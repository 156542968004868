import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Location } from "../../../interfaces/location.interface";
import { LocationType } from "../../../enums/location-type.enum";
import {
  addLocation,
  getLocation,
  updateLocation,
} from "../../../utils/rest-api/locations";
import { useApp } from "../../../contexts/AppContext";
import { ExtendedSelect } from "../../common/ExtendedSelect";
import classNames from "classnames";
import { Timezone } from "./Timezone";
import { ActionMeta, SingleValue } from "react-select";

export interface LocationFormProps extends PropsWithChildren {
  className?: string;
  locationId?: string;
}

const LocationForm: FC<LocationFormProps> = (props) => {
  const [location, setLocation] = useState<Location>({
    _id: "",
    code: "",
    country: "",
    port: "",
    address: "",
    type: LocationType.DESTINATION,
    isActive: true,
    timezone: "Asia/Jakarta",
  });

  const [invalidLocationProps, setInvalidLocationProps] = useState({
    code: "",
    country: "",
    port: "",
    address: "",
    type: "",
    isActive: "",
    timezone: "",
  });

  const navigate = useNavigate();
  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    if (props.locationId) {
      showLoader();
      getLocation(props.locationId)
        .then((res) => {
          const location = res.data.data as Location;
          setLocation({ ...location });
        })
        .catch((e) => {
          toast.error(e.response.data.message ?? e.message);
        })
        .finally(() => hideLoader());
    }
  }, []);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocation({ ...location, [e.currentTarget.name]: e.currentTarget.value });
    setInvalidLocationProps({
      ...invalidLocationProps,
      [e.currentTarget.name]: "",
    });
  };

  const onSelectInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLocation({ ...location, [e.currentTarget.name]: e.currentTarget.value });
    setInvalidLocationProps({
      ...invalidLocationProps,
      [e.currentTarget.name]: "",
    });
  };

  const onActiveSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocation({ ...location, isActive: !location.isActive });
  };

  const handleError = (err: any) => {
    if (err.response.data) {
      if (typeof err.response.data?.message == "object") {
        setInvalidLocationProps({
          ...invalidLocationProps,
          ...err.response.data.message,
        });
      } else {
        toast.error(err.response.data?.message ?? err.response.data.error);
      }
    } else {
      toast.error("Please check your network connection");
    }
  };

  const onSave = () => {
    const locationData = { ...location };
    delete locationData._id;
    if (props.locationId) {
      showLoader();
      updateLocation(props.locationId, locationData)
        .then((res) => {
          toast.success("Provider successfully updated");
          navigate("/locations");
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => hideLoader());
    } else {
      showLoader();
      addLocation(locationData)
        .then((res) => {
          toast.success("Provider successfully added");
          navigate("/locations");
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => hideLoader());
    }
  };

  const getTimezoneLabel = (timezone: string) => {
    const now = new Date();
    const utcDate = new Date(now.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(
      now.toLocaleString("en-US", { timeZone: timezone })
    );
    const offset = utcDate.getTime() - tzDate.getTime();
    const offsetInMinutes = offset / 60000;
    const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const offsetMinutes = Math.abs(offsetInMinutes) % 60;
    const sign = offsetInMinutes > 0 ? "-" : "+";
    return `${timezone} (${sign}${String(offsetHours).padStart(
      2,
      "0"
    )}:${String(offsetMinutes).padStart(2, "0")})`;
  };

  const timeZoneList = Timezone.map((tz) => {
    const timezoneString = getTimezoneLabel(tz);
    return { value: tz, label: `${timezoneString}` };
  });

  return (
    <div className={`ps-2 pe-2 pb-30 ${props.className}`}>
      <div className="mb-20">
        <span className="text-30 lh-14 fw-600">
          {props.locationId ? "Edit" : "Add"} Location
        </span>
      </div>
      <Form className="w-form-provider" onSubmit={(e) => e.preventDefault()}>
        <Form.Group className="mb-2">
          <Form.Label>Country</Form.Label>
          <Form.Control
            className={invalidLocationProps.country && `border-error-2`}
            type="text"
            name="country"
            value={location.country}
            onChange={onInputChange}
          />
          <Form.Text className={`text-error-2`}>
            {invalidLocationProps.country}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Port</Form.Label>
          <Form.Control
            className={invalidLocationProps.port && `border-error-2`}
            type="text"
            name="port"
            value={location.port}
            onChange={onInputChange}
          />
          <Form.Text className={`text-error-2`}>
            {invalidLocationProps.port}
          </Form.Text>
        </Form.Group>
        {/* <Form.Group className="mb-2">
          <Form.Label>ISO2 Code</Form.Label>
          <Form.Control
            className={invalidLocationProps.code && `border-error-2`}
            type="text"
            name="code"
            value={location.code}
            onChange={onInputChange}
          />
          <Form.Text className={`text-error-2`}>
            {invalidLocationProps.code}
          </Form.Text>
        </Form.Group> */}
        <Form.Group className="mb-2">
          <Form.Label>Timezone</Form.Label>
          <ExtendedSelect
            classNames={{
              control: ({ isFocused }) =>
                classNames(isFocused && "react-select-focus"),
            }}
            aria-label="Timezone"
            getOptionLabel={(tz: { label: string; value: string }) => tz.label}
            getOptionValue={(tz: { label: string; value: string }) => tz.value}
            options={timeZoneList}
            value={{
              value: location.timezone,
              label: getTimezoneLabel(location.timezone),
            }}
            onInputChange={() => {
              setInvalidLocationProps({
                ...invalidLocationProps,
                timezone: "",
              });
            }}
            onChange={(
              timezone: SingleValue<{ label: string; value: string }>,
              action: ActionMeta<{ label: string; value: string }>
            ) => {
              if (timezone?.value) {
                setLocation({ ...location, timezone: timezone?.value });
              }
              setInvalidLocationProps({
                ...invalidLocationProps,
                timezone: "",
              });
            }}
          />
          <Form.Text className={`text-error-2`}>
            {invalidLocationProps.timezone}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-20">
          <Form.Label>Type</Form.Label>
          <Form.Select
            className={invalidLocationProps.type && `border-error-2`}
            name="type"
            aria-label="Origin"
            style={{ height: "44px" }}
            value={location.type}
            onChange={onSelectInputChange}
          >
            {Object.values(LocationType).map((type) => {
              return (
                <option key={`type-${type}`} value={type}>
                  {`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
                </option>
              );
            })}
          </Form.Select>
          <Form.Text className={`text-error-2`}>
            {invalidLocationProps.type}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-40">
          <Form.Check
            type="switch"
            label="Active"
            name="isActive"
            checked={location.isActive}
            onChange={onActiveSwitchChange}
          />
        </Form.Group>
        <Button onClick={onSave} variant="primary" className="w-100">
          Save
        </Button>
      </Form>
    </div>
  );
};

export default LocationForm;
