import React, { useEffect } from "react";
import { pages } from "../router";
import { me } from "../utils/rest-api/users";
import { useLocation, useNavigate } from "react-router-dom";
import http from "../utils/axios";
import axios from "axios";

export interface User {
  _id:string;
  email:string;
  fullName:string;
  roles:string;
  createdAt:Date;
}

export interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User|undefined;
  accessGranted: boolean;
  setAuthenticate: (token:string) => void;
  setUnAuthenticate: () => void;
}

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: undefined,
  accessGranted: false,
  setAuthenticate: (token:string) => {},
  setUnAuthenticate: () => {}
}

type Action =
    {
      type: "SET_READY";
    }
  | {
      type: "SET_AUTHENTICATED";
      user: User;
    }
  | {
      type: "SET_UNAUTHENTICATED";
    }
  | {
      type: "SET_AUTHORIZED";
    }
  | {
      type: "SET_UNAUTHORIZED";
    };

export const AuthContext = React.createContext<AuthState>(initialState);

AuthContext.displayName = "AuthContext";

function uiReducer(state: AuthState, action: Action):AuthState {
  switch (action.type) {
    case "SET_READY": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "SET_AUTHENTICATED": {
      return  {
        ...state,
        isAuthenticated: true,
        user: action.user
      }
    }
    case "SET_UNAUTHENTICATED": {
      return {
        ...state,
        isAuthenticated: false
      }
    }
    case "SET_AUTHORIZED": {
      return {
        ...state,
        accessGranted: true
      }
    }
    case "SET_UNAUTHORIZED": {
      return {
        ...state,
        accessGranted: false
      }
    }
    default: {
      return { ...state }
    }
  }
}

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export const AuthProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  
  const authenticate = async () => 
  {
    try {
      const res = await me();
      const user = res.data.data;
      dispatch({ type:"SET_AUTHENTICATED", user: user });
    } catch (e) { 
      //not authenticated 
    }
  }
  
  const setAuthenticate = (token:string) => {
    localStorage.setItem("access_token", token);
    authenticate();
  };
  
  const setUnAuthenticate = () => {
    localStorage.removeItem("access_token");
    dispatch({ type:"SET_UNAUTHENTICATED" });
  }

  http.interceptors.request.use((axiosConfig) => {
    if (!axiosConfig.isPublic) {
      const token = localStorage.getItem("access_token");
      if (!token) {
        setUnAuthenticate();
        throw new axios.Cancel("token not found");
      }
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return axiosConfig;
  });

  http.interceptors.response.use((response) => {
    if (response.status == 401) {
      setUnAuthenticate();
    }
    return response;
  });

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      dispatch({ type: "SET_READY" })
    }
    authenticate().then((res) => {
      dispatch({ type: "SET_READY"})
    }).catch((err) => {
      dispatch({ type: "SET_READY"})
    });
  }, []);

  useEffect(() => {
    if (state.isLoading) return;
    const route = pages.find((page) => {
      const regex = page.path.replace(/:[A-Za-z0-9]+/g, "[A-Za-z0-9]+");
      return location.pathname.match(new RegExp(regex, "g"));
    });
    if (!route) {
      // return navigate("/404", {
      //   replace: true
      // });
      return;
    }
    if (state.isAuthenticated && location.pathname == "/login") {
      navigate("/");
      return 
    }
    const guestAllowedPath = route.roles.indexOf("guest") >= 0;
    if (guestAllowedPath) return; //not authenticated routes
    if (!state.isAuthenticated) { 
      navigate("/login")
      return;
    }
    //user authenticated should add check if user roles can access the routes
  }, [state.isAuthenticated, state.isLoading]);


  return <AuthContext.Provider value={{...state, setAuthenticate, setUnAuthenticate}} {...props} />;
}
