import { FC, PropsWithChildren } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import dayjs from "dayjs";
import { User } from "../../../interfaces/user.interface";

export interface UserTableProps extends PropsWithChildren {
  className?: string,
  page: number,
  total: number,
  data: Array<User>,
  onEdit: (user:User) => void,
  onPageChange: (page:number) => void
}

const UserTable:FC<UserTableProps> = (props) => {
  return (
    <div className={props.className || ""}>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Fullname</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Role</th>
            <th className="md:d-none">Last Updated</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            props.data && props.data.length > 0 ? (
              props.data.map((dt, i)=> {
                return (
                  <tr key={`user-${i}`}>
                    <td>{dt.fullName}</td>
                    <td>{dt.phone}</td>
                    <td>{dt.email}</td>
                    <td><Badge bg="secondary">{dt.role}</Badge></td>
                    <td className="md:d-none">{dayjs(dt.updatedAt).format("HH:mm DD-MMM-YYYY")}</td>
                    <td>
                      <Button variant="primary" onClick={(e) => props.onEdit(dt)}>Edit</Button>
                    </td>
                  </tr>
                )
              })
            )  : 
            <tr>
              <td className="text-center" colSpan={5}>No Data Available</td>
            </tr>
          }
        </tbody>
      </Table>
      <Pagination page={props.page} totalPage={props.total} onPageClick={(page)=>props.onPageChange(page)} />
    </div>
  );
};

export default UserTable;
