import { Button, Form } from "react-bootstrap";
import ProviderTable from "../../components/dashboard/provider/ProviderTable";
import { PageConfig } from "../../router";
import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { getProviders } from "../../utils/rest-api/providers";
import { useApp } from "../../contexts/AppContext";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - Booking Page",
  description: "Luna - Booking Page",
};

export default function ProviderPage() {
  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    showLoader();
    getProviders()
      .then((res) => {
        const data = res.data.data;
        setProviders(data);
        const total = res.data.totalPages;
        setTotalPages(total);
      })
      .finally(() => hideLoader());
  }, [page]);

  const [query, setQuery] = useState("");
  let timeoutId: MutableRefObject<NodeJS.Timeout | undefined> =
    useRef(undefined);

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
    if (timeoutId && timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
    timeoutId.current = setTimeout(
      (queryValue) => {
        showLoader();
        getProviders(
          queryValue,
          status == "all" ? undefined : status == "active" ? true : false
        )
          .then((res) => {
            const data = res.data.data;
            setProviders(data);
            const total = res.data.totalPages;
            setTotalPages(total);
          })
          .finally(() => hideLoader());
      },
      500,
      e.currentTarget.value
    );
  };

  const statuses = ["all", "active", "inactive"];
  const [status, setStatus] = useState("all");
  const onStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.currentTarget.value);
    showLoader();
    getProviders(
      query,
      e.currentTarget.value == "all"
        ? undefined
        : e.currentTarget.value == "active"
        ? true
        : false
    )
      .then((res) => {
        const data = res.data.data;
        setProviders(data);
        const total = res.data.totalPages;
        setTotalPages(total);
      })
      .finally(() => hideLoader());
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="d-flex flex-row mb-20">
              <span className="text-30 lh-14 fw-600">Provider</span>
            </div>
            <div
              className="d-flex md:flex-column flex-row mb-20"
              style={{ gap: "5px" }}
            >
              <Form.Group
                className="w-filter-control"
                style={{ height: "100%" }}
              >
                <Form.Control
                  style={{ height: "100%" }}
                  type="text"
                  name="query"
                  value={query}
                  onChange={onQueryChange}
                  placeholder="Search"
                />
              </Form.Group>
              <Form.Select
                className="w-filter-control"
                style={{ height: "100%" }}
                value={status}
                onChange={onStatusChange}
              >
                {statuses.map((status) => {
                  return (
                    <option key={`status-${status}`} value={status}>{`${status
                      .charAt(0)
                      .toUpperCase()}${status.slice(1)}`}</option>
                  );
                })}
              </Form.Select>
              <div className="flex-grow-1 md:d-none"></div>
              <Button
                variant="primary"
                onClick={(e) => navigate("/providers/add")}
              >
                Add Provider
              </Button>
            </div>
            <ProviderTable
              data={providers}
              className="h-100 md-overflow-y-visible overflow-y-scroll"
              total={totalPages}
              page={page}
              onPageChange={(page) => {
                setPage(page);
              }}
              onEdit={(provider) => navigate(`/providers/${provider._id}`)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const ProviderPageConfig: PageConfig = {
  path: "/providers",
  component: ProviderPage,
  roles: ["admin", "root"],
};
