import { Button } from "react-bootstrap";
import { PageConfig } from "../../router";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocations } from "../../utils/rest-api/locations";
import { Location } from "../../interfaces/location.interface";
import LocationTable from "../../components/dashboard/location/LocationTable";
import { useApp } from "../../contexts/AppContext";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - Location Page",
  description: "Luna - Location Page",
};

export default function LocationPage() {
  const [locations, setLocations] = useState<Location[]>([]);
  const { showLoader, hideLoader } = useApp();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    showLoader();
    getLocations()
      .then((res) => {
        const total = res.data.totalPages;
        setTotalPages(total);
        const data = res.data.data;
        setLocations(data);
      })
      .finally(() => {
        hideLoader();
      });
  }, [page]);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="d-flex flex-row mb-20">
              <span className="text-30 lh-14 fw-600">Location</span>
              <div className="flex-grow-1"></div>
              <Button
                variant="primary"
                onClick={(e) => navigate("/locations/add")}
              >
                Add Location
              </Button>
            </div>
            <LocationTable
              className="h-100 md-overflow-y-visible overflow-y-scroll"
              data={locations}
              total={totalPages}
              page={page}
              onPageChange={(page) => {
                setPage(page);
              }}
              onEdit={(location) => navigate(`/locations/${location._id}`)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const LocationPageConfig: PageConfig = {
  path: "/locations",
  component: LocationPage,
  roles: ["admin", "root"],
};
