import React, { useEffect, useRef, useState } from "react";
import { getBookingCount } from "../utils/rest-api/bookings";
import { useAuth } from "./AuthContext";

export interface AppState {
  loaderVisibility: boolean,
  showLoader: ()=>void,
  hideLoader: ()=>void,
  newBookingCount: number
}

const initialState = {
  loaderVisibility: false,
  showLoader: ()=>{},
  hideLoader: ()=>{},
  newBookingCount: 0
};

type Action = { type:"SHOW_LOADER" } |
      { type:"HIDE_LOADER" } | 
      { type:"SET_BOOKING_COUNT", count: number }

export const AppContext = React.createContext<AppState>(initialState);

AppContext.displayName = "AppContext";

function uiReducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "SHOW_LOADER": {
      return {
        ...state,
        loaderVisibility: true,
      };
    }
    case "HIDE_LOADER": {
      return {
        ...state,
        loaderVisibility: false,
      };
    }
    case "SET_BOOKING_COUNT": {
      return {
        ...state,
        newBookingCount: action.count
      }
    }
    default: {
      return { ...state };
    }
  }
}

export const useApp = () => {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useApp must be used within a AppProvider`);
  }
  return context;
};

export const AppProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);
  const showLoader = async () => dispatch({ type:"SHOW_LOADER" });
  const hideLoader = async () => dispatch({ type:"HIDE_LOADER" });
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    let intervalKey:any = null;
    if (isAuthenticated) {
      intervalKey = setInterval(() => {
        const data = localStorage.getItem("lastFetchBooking") as string;
        let date = new Date(data);
        if (!(date instanceof Date)) {
          date = new Date();
        }
        getBookingCount(date.toISOString()).then((res) => {
          dispatch({ type:"SET_BOOKING_COUNT", count: res.data.data });
        });
      }, 1000);
    }
    return () => {
      if (intervalKey) {
        clearInterval(intervalKey);
      }
    }
  }, [isAuthenticated])

  return <AppContext.Provider value={{...state, showLoader, hideLoader}} {...props} />;
};
