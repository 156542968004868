import { FC } from "react";
import { LoginPageConfig } from "./pages/login";
import { DashboardPageConfig } from "./pages/dashboard";
import { NotFound404PageConfig } from "./pages/404";
import { BookingPageConfig } from "./pages/booking";
import { ProviderPageConfig } from "./pages/provider";
import { SchedulePageConfig } from "./pages/schedule";
import { AddProviderPageConfig } from "./pages/provider/add-provider";
import { EditProviderPageConfig } from "./pages/provider/edit-provider";
import { AddSchedulePageConfig } from "./pages/schedule/add-schedule";
import { EditSchedulePageConfig } from "./pages/schedule/edit-schedule";
import { UserPageConfig } from "./pages/user";
import { AddUserPageConfig } from "./pages/user/add-user";
import { EditUserPageConfig } from "./pages/user/edit-user";
import { ViewUserPassportPageConfig } from "./pages/user/view-user-passport";
import { ViewUserWalletMutationPageConfig } from "./pages/user/view-user-wallet-mutation";
import { LocationPageConfig } from "./pages/location";
import { AddLocationPageConfig } from "./pages/location/add-location";
import { EditLocationPageConfig } from "./pages/location/edit-location";
import { SettingPageConfig } from "./pages/setting";
import { ViewBookingPageConfig } from "./pages/booking/view";
import { ProcessBookingPageConfig } from "./pages/booking/process";
import { ItinerayPageConfig } from "./pages/booking/view-booking-itineray";

export type AuthRole = "guest" | "admin" | "root";

export interface PageConfig {
  path: string;
  component: FC;
  roles: Array<AuthRole>;
}

export const dashboardPages: Array<PageConfig> = [
  DashboardPageConfig,
  BookingPageConfig,
  ViewBookingPageConfig,
  ProcessBookingPageConfig,
  ProviderPageConfig,
  AddProviderPageConfig,
  EditProviderPageConfig,
  UserPageConfig,
  AddUserPageConfig,
  EditUserPageConfig,
  ViewUserPassportPageConfig,
  ViewUserWalletMutationPageConfig,
  SchedulePageConfig,
  AddSchedulePageConfig,
  EditSchedulePageConfig,
  LocationPageConfig,
  AddLocationPageConfig,
  EditLocationPageConfig,
  SettingPageConfig,
];

export const nonDashboardAppPages: Array<PageConfig> = [ItinerayPageConfig];

export const publicAppPages: Array<PageConfig> = [
  LoginPageConfig,
  NotFound404PageConfig,
];

export const pages = [
  ...dashboardPages,
  ...nonDashboardAppPages,
  ...publicAppPages,
];
