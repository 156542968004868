import axios from "axios";

declare module 'axios' {
  export interface AxiosRequestConfig {
    isPublic?: boolean;
  }
}

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default http;