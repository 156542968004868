import { Link } from "react-router-dom";
import { logout } from "../../../utils/rest-api/auth";
import { useAuth } from "../../../contexts/AuthContext";
import { FC } from "react";
import { useApp } from "../../../contexts/AppContext";

export interface SidebarProps {
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: FC<SidebarProps> = ({ setSideBarOpen }) => {
  // const sidebarData = [
  //   {
  //     icon: "/img/dashboard/sidebar/canoe.svg",
  //     title: "Manage Schedule",
  //     links: [
  //       {
  //         title: "All Schedule",
  //         href: "#",
  //       },
  //       {
  //         title: "Add Schedule",
  //         href: "#",
  //       },
  //       {
  //         title: "Recovery",
  //         href: "#",
  //       },
  //     ],
  //   },
  //   {
  //     icon: "/img/dashboard/sidebar/bookmark.svg",
  //     title: "Manage Provider",
  //     links: [
  //       {
  //         title: "All Provider",
  //         href: "#",
  //       },
  //       {
  //         title: "Add Provider",
  //         href: "#",
  //       },
  //       {
  //         title: "Recovery",
  //         href: "#",
  //       },
  //     ],
  //   }
  // ];

  const { setUnAuthenticate } = useAuth();
  const { showLoader, hideLoader, newBookingCount } = useApp();

  const onLogout = () => {
    showLoader();
    logout()
      .then((res) => {
        setUnAuthenticate();
      })
      .catch((err) => {
        // just delete token
        setUnAuthenticate();
      })
      .finally(() => hideLoader());
  };

  return (
    <>
      <div className="sidebar -dashboard" id="vendorSidebarMenu">
        <div className="sidebar__item ">
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
          >
            <img
              src="/img/dashboard/sidebar/compass.svg"
              alt="image"
              className="mr-15"
            />
            Dashboard
          </Link>
        </div>
        {/* End accordion__item */}

        <div className="sidebar__item ">
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/bookings"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
          >
            <img
              src="/img/dashboard/sidebar/booking.svg"
              alt="image"
              className="mr-15"
            />
            Booking Manager
            {newBookingCount > 0 && (
              <div className="bg-info-1 text-14 p-2 rounded-1 ml-10">
                {newBookingCount > 99 ? "+99" : newBookingCount}
              </div>
            )}
          </Link>
        </div>
        {/* End accordion__item */}

        <div className="sidebar__item ">
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/schedules"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
          >
            <img
              src="/img/dashboard/sidebar/canoe.svg"
              alt="image"
              className="mr-15"
            />
            Manage Schedule
          </Link>
        </div>
        {/* End accordion__item */}

        <div className="sidebar__item ">
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/providers"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
          >
            <img
              src="/img/dashboard/sidebar/bookmark.svg"
              alt="image"
              className="mr-15"
            />
            Manage Provider
          </Link>
        </div>

        <div className="sidebar__item ">
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/users"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
          >
            <img
              src="/img/dashboard/sidebar/sneakers.svg"
              alt="image"
              className="mr-15"
            />
            Manage User
          </Link>
        </div>

        <div className="sidebar__item ">
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/locations"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
          >
            <img
              src="/img/dashboard/sidebar/airplane.svg"
              alt="image"
              className="mr-15"
            />
            Manage Location
          </Link>
        </div>

        <div className="sidebar__item ">
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/settings"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
          >
            <img
              src="/img/dashboard/sidebar/gear.svg"
              alt="image"
              className="mr-15"
            />
            Settings
          </Link>
        </div>
        {/* End accordion__item */}

        {/* {sidebarData.map((item, index) => (
          <div className="sidebar__item" key={index}>
            <div className="accordion -db-sidebar js-accordion">
              <div className="accordion__item">
                <div
                  className="accordion__button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#sidebarItem${index}`}
                >
                  <div className="sidebar__button col-12 d-flex items-center justify-between">
                    <div className="d-flex items-center text-15 lh-1 fw-500">
                      <img src={item.icon} alt="image" className="mr-10" />
                      {item.title}
                    </div>
                    <div className="icon-chevron-sm-down text-7" />
                  </div>
                </div>
                <div
                  id={`sidebarItem${index}`}
                  className="collapse"
                  data-bs-parent="#vendorSidebarMenu"
                >
                  <ul className="list-disc pt-15 pb-5 pl-40">
                    {item.links.map((link, linkIndex) => (
                      <li key={linkIndex}>
                        <a href={link.href} className="text-15">
                          {link.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))} */}

        <div className="sidebar__item ">
          <a
            href="#"
            className="sidebar__button d-flex items-center text-15 lh-1 fw-500"
            onClick={onLogout}
          >
            <img
              src="/img/dashboard/sidebar/log-out.svg"
              alt="image"
              className="mr-15"
            />
            Logout
          </a>
        </div>
        {/* End accordion__item */}
      </div>
    </>
  );
};

export default Sidebar;
