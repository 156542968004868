import { Button, Form, Modal } from "react-bootstrap";
import { MutableRefObject, useEffect, useState, useRef } from "react";
import { AxiosResponse } from "axios";
import classNames from "classnames";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";

import BookingTable from "../../components/dashboard/booking/BookingTable";
import { PageConfig } from "../../router";
import {
  approveCancellationRequestBooking,
  getBookings,
  processBooking,
  rejectCancellationRequestBooking,
  rejectPaidBooking,
} from "../../utils/rest-api/bookings";
import { Booking } from "../../interfaces/booking.interface";
import { useApp } from "../../contexts/AppContext";
import { BookingStatus } from "../../enums/booking.enum";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import MetaComponent from "../../components/common/MetaComponent";
import { User, UserResponse } from "../../interfaces/user.interface";
import { getUsers } from "../../utils/rest-api/users";
import { ActionMeta, SingleValue } from "react-select";

const metadata = {
  title: "Luna - Booking Page",
  description: "Luna - Booking Page",
};

export enum BookingActionButton {
  APPROVE_CANCELLATION = 0,
  REJECT_CANCELLATION = 1,
  REJECT_PAID = 2,
}

export type BookingActionModalProps = {
  show: boolean;
  action: BookingActionButton | undefined;
  booking: Booking | undefined;
  reason: string;
  invalidReason?: string;
};

interface BookingFilterProp {
  id?: string;
  user?: User;
  selectedStatus: string;
  page: number;
  limit: number;
}

export default function BookingPage() {
  const statusOptions = [
    { name: "All Booking", value: "" },
    { name: "Waiting For Payment", value: BookingStatus.WAITING_FOR_PAYMENT },
    {
      name: "Request For Cancellation",
      value: BookingStatus.REQUEST_FOR_CANCELLATION,
    },
    { name: "Paid", value: BookingStatus.PAID },
    { name: "Processing", value: BookingStatus.PROCESSING },
    { name: "Expired", value: BookingStatus.EXPIRED },
    { name: "Rejected", value: BookingStatus.REJECTED },
    { name: "Cancelled", value: BookingStatus.CANCELLED },
    { name: "Issued", value: BookingStatus.ISSUED },
  ];

  let timeoutId: MutableRefObject<NodeJS.Timeout | undefined> =
    useRef(undefined);

  const [bookingId, setBookingId] = useState<string>();

  const onQueryChange = (q?: string) => {
    setBookingId(q);
    if (timeoutId && timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
    timeoutId.current = setTimeout(
      (queryValue) => {
        setBookingFilter((prev) => ({
          ...prev,
          page: 1,
          id: queryValue,
        }));
      },
      500,
      q
    );
  };

  const [bookingFilter, setBookingFilter] = useState<BookingFilterProp>({
    selectedStatus: "",
    page: 1,
    limit: 10,
  });

  const [totalPages, setTotalPages] = useState(1);
  const { user } = useAuth();

  const handleStatusChange = (status: string) => {
    setBookingFilter((prev) => ({ ...prev, page: 1, selectedStatus: status }));
  };

  const navigate = useNavigate();
  const [bookings, setBookings] = useState<Array<Booking>>([]);
  const { showLoader, hideLoader } = useApp();

  const fetchData = () => {
    showLoader();
    getBookings(
      bookingFilter.selectedStatus || undefined,
      bookingFilter.page,
      bookingFilter.limit,
      bookingFilter.user?._id,
      bookingFilter.id
    )
      .then((res) => {
        const data = res.data.data as Array<Booking>;
        const totalPages = res.data.totalPages;
        setTotalPages(totalPages);
        setBookings([...data]);
        localStorage.setItem("lastFetchBooking", new Date().toISOString());
      })
      .finally(() => {
        hideLoader();
      });
  };

  useEffect(() => {
    fetchData();
  }, [bookingFilter]);

  const [modal, setModal] = useState<BookingActionModalProps>({
    show: false,
    action: undefined,
    booking: undefined,
    reason: "",
  });
  const renderModalTitle = () => {
    switch (modal.action) {
      case BookingActionButton.APPROVE_CANCELLATION:
        return "Approve Cancellation Request";
      case BookingActionButton.REJECT_CANCELLATION:
        return "Reject Cancellation Request";
      case BookingActionButton.REJECT_PAID:
        return "Reject Paid Booking";
    }
  };

  const closeModal = () => {
    setModal({
      show: false,
      action: undefined,
      booking: undefined,
      reason: "",
    });
  };

  const onModalProcessButtonClick = () => {
    switch (modal.action) {
      case BookingActionButton.APPROVE_CANCELLATION:
        showLoader();
        approveCancellationRequestBooking(modal.booking?._id!)
          .then((res) => {
            toast.success("Cancellation request successfully approved!");
            closeModal();
            fetchData();
          })
          .catch((e) => {
            toast.error(
              e.response?.data.message || e.response?.error || "Network error"
            );
          })
          .finally(() => {
            hideLoader();
          });
        break;
      case BookingActionButton.REJECT_CANCELLATION:
        showLoader();
        rejectCancellationRequestBooking(modal.booking?._id!, modal.reason)
          .then((res) => {
            toast.success("Cancellation request successfully rejected!");
            closeModal();
            fetchData();
          })
          .catch((e) => {
            if (e.response.status == 400) {
              setModal({
                ...modal,
                invalidReason: e.response.data?.message?.reason,
              });
            } else {
              toast.error(
                e.response?.data.message || e.response?.error || "Network error"
              );
            }
          })
          .finally(() => {
            hideLoader();
          });
        break;
      case BookingActionButton.REJECT_PAID:
        showLoader();
        rejectPaidBooking(modal.booking?._id!, modal.reason)
          .then((res) => {
            toast.success("Booking successfuly rejected!");
            closeModal();
            fetchData();
          })
          .catch((e) => {
            if (e.response.status == 400) {
              setModal({
                ...modal,
                invalidReason: e.response.data?.message?.reason,
              });
            } else {
              toast.error(
                e.response?.data.message || e.response?.error || "Network error"
              );
            }
          })
          .finally(() => {
            hideLoader();
          });
        break;
    }
  };

  const animatedComponents = makeAnimated();

  let abortController: AbortController | undefined = undefined;
  let providerTimeoutId: NodeJS.Timeout | undefined = undefined;

  const loadOptions = (
    inputValue: string,
    callback: (options: User[]) => void
  ) => {
    if (providerTimeoutId) {
      clearTimeout(providerTimeoutId);
      providerTimeoutId = undefined;
    }

    providerTimeoutId = setTimeout(() => {
      abortController = new AbortController();
      getUsers(
        { q: inputValue, role: ["user", "agent"], page: 1, limit: 20 },
        abortController
      )
        .then((res: AxiosResponse<UserResponse>) => {
          if (abortController) callback(res.data.data ?? []);
        })
        .catch((e) => {
          callback([]);
        })
        .finally(() => {
          abortController = undefined;
        });
      providerTimeoutId = undefined;
    }, 1000);
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="d-flex flex-row mb-20">
              <span className="text-30 lh-14 fw-600">Booking</span>
            </div>

            <div
              className="d-flex md:flex-column flex-row mb-20"
              style={{ gap: "5px" }}
            >
              <Form.Group
                className="w-filter-control"
                style={{ height: "100%" }}
              >
                <Form.Control
                  style={{ height: "100%" }}
                  type="text"
                  name="query"
                  value={bookingId}
                  onChange={(e) => {
                    onQueryChange(e.currentTarget.value);
                  }}
                  placeholder="Booking ID"
                />
              </Form.Group>
              <AsyncSelect
                isMulti={false}
                classNames={{
                  container: () => "w-filter-control w-260 h-100",
                  control: ({ isFocused }) =>
                    classNames(isFocused && "react-select-focus", "h-100"),
                }}
                components={animatedComponents}
                menuPlacement="auto"
                aria-label="User"
                placeholder="Search User"
                getOptionLabel={(user: User) => user.email}
                getOptionValue={(user: User) => user._id ?? ""}
                defaultInputValue={undefined}
                defaultValue={undefined}
                isClearable
                blurInputOnSelect
                noOptionsMessage={(e) => {
                  return "No User found";
                }}
                value={bookingFilter.user}
                isOptionSelected={(option, value) => {
                  return !!value.find((e) => e._id == option._id);
                }}
                hideSelectedOptions
                onChange={(
                  user: SingleValue<User>,
                  action: ActionMeta<User>
                ) => {
                  setBookingFilter((prev) => ({
                    ...prev,
                    page: 1,
                    user: user ?? undefined,
                  }));
                }}
                pageSize={1}
                loadOptions={loadOptions}
                cacheOptions
                defaultOptions
              />

              <Form.Select
                className="w-filter-control"
                style={{ height: "auto", width: "fit-content" }}
                value={bookingFilter.selectedStatus}
                onChange={(e) => handleStatusChange(e.currentTarget.value)}
              >
                {statusOptions.map((status, index) => {
                  return (
                    <option
                      key={`status-${index}`}
                      value={status.value}
                    >{`${status.name}`}</option>
                  );
                })}
              </Form.Select>
              <div className="flex-grow-1 md:d-none"></div>
            </div>

            {/* <div className="tabs -underline-2 js-tabs lg:d-none d-flex">
              <div className="tabs__controls row x-gap-20 y-gap-10 lg:x-gap-20 js-tabs-controls mb-30">
                {statusOptions.map((status, index) => (
                  <div className="col-auto" key={`status-${index}`}>
                    <button
                      className={`tabs__button text-14 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button ${
                        status.value === selectedStatus
                          ? "is-tab-el-active"
                          : ""
                      }`}
                      onClick={() => handleStatusChange(status.value)}
                    >
                      {status.name}
                    </button>
                  </div>
                ))}
              </div>
            </div> */}
            {/* <div className="mb-30 lg:d-flex d-none">
              <Form.Select
                value={bookingFilter.selectedStatus}
                onChange={(e) => handleStatusChange(e.currentTarget.value)}
              >
                {statusOptions.map((status) => {
                  return (
                    <option key={status.name} value={status.value}>
                      {status.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div> */}
            <BookingTable
              page={bookingFilter.page}
              total={totalPages}
              data={bookings}
              onApproveCancellationRequest={(booking) => {
                setModal({
                  show: true,
                  action: BookingActionButton.APPROVE_CANCELLATION,
                  booking: booking,
                  reason: "",
                });
              }}
              onRejectCancellationRequest={(booking) => {
                setModal({
                  show: true,
                  action: BookingActionButton.REJECT_CANCELLATION,
                  booking: booking,
                  reason: "",
                });
              }}
              onRejectPaidBooking={(booking) => {
                setModal({
                  show: true,
                  action: BookingActionButton.REJECT_PAID,
                  booking: booking,
                  reason: "",
                });
              }}
              onProcessBooking={(booking) => {
                if (
                  booking.status == BookingStatus.PROCESSING &&
                  booking.processedBy == user?._id
                ) {
                  navigate(`/bookings/${booking._id}/process`);
                } else {
                  showLoader();
                  processBooking(booking._id!)
                    .then((res) => {
                      navigate(`/bookings/${booking._id}/process`);
                    })
                    .catch((e) => {
                      toast.error(
                        e.response?.data.message ||
                          e.response?.error ||
                          "Network error"
                      );
                    })
                    .finally(() => {
                      hideLoader();
                    });
                }
              }}
              onViewBooking={(booking: Booking) => {
                navigate(`/bookings/${booking._id}`);
              }}
              onPageChange={(page: number) => {
                setBookingFilter((prev) => ({ ...prev, page: page }));
              }}
              className="h-100 md-overflow-y-visible overflow-y-scroll"
            />
          </div>
          <Modal
            show={modal.show}
            onHide={() =>
              setModal({
                show: false,
                action: undefined,
                booking: undefined,
                reason: "",
              })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>{renderModalTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>Are you sure want to process this action?</span>
              {(modal.action == BookingActionButton.REJECT_CANCELLATION ||
                modal.action == BookingActionButton.REJECT_PAID) && (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    className={modal.invalidReason && `border-error-2`}
                    style={{ resize: "none" }}
                    as="textarea"
                    rows={3}
                    value={modal.reason}
                    onChange={(e) => {
                      setModal({ ...modal, reason: e.currentTarget.value });
                    }}
                  />
                  <Form.Text className={`text-error-2`}>
                    {modal.invalidReason}
                  </Form.Text>
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={(e) =>
                  setModal({
                    show: false,
                    action: undefined,
                    booking: undefined,
                    reason: "",
                  })
                }
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  onModalProcessButtonClick();
                }}
              >
                Process
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export const BookingPageConfig: PageConfig = {
  path: "/bookings",
  component: BookingPage,
  roles: ["admin", "root"],
};
