import ScheduleForm from "../../components/dashboard/schedule/ScheduleForm";
import { PageConfig } from "../../router";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - Schedule Page",
  description: "Luna - Schedule Page",
};

export default function AddSchedulePage() {
  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 mb-20 overflow-y-scroll">
            <ScheduleForm />
          </div>
        </div>
      </div>
    </>
  );
}

export const AddSchedulePageConfig: PageConfig = {
  path: "/schedules/add",
  component: AddSchedulePage,
  roles: ["admin", "root"],
};
