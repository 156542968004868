import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { FC } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },

    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      // backgroundColor: "rgba(72, 241, 12, 1)",
      // borderColor: "rgb(255, 99, 132)",
      backgroundColor: "#1967d2",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 4,
    },
  },
};

export type DataSet = {
  label: string;
  amount: number;
  count: number;
};

export interface ChartMainProps {
  datasets: DataSet[];
}

const ChartMain: FC<ChartMainProps> = ({ datasets }) => {
  let labels = datasets.map((e) => {
    return e.label;
  });

  let amounts = datasets.map((e) => {
    return e.amount;
  });

  let counts = datasets.map((e) => {
    return e.count;
  });

  return (
    <div className="widget-content">
      <Line
        options={options}
        data={{
          labels,
          datasets: [
            {
              label: "Amount",
              data: amounts,
              borderColor: "#1967d2",
              backgroundColor: "#1967d2",
              fill: false,
            },
            {
              label: "Count",
              data: counts,
              borderColor: "#088F8F",
              backgroundColor: "#088F8F",
              fill: false,
            },
          ],
        }}
      />
    </div>
  );
};

export default ChartMain;
