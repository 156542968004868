import { PageConfig } from "../../router";
import BookingForm from "../../components/dashboard/booking/BookingForm";
import { useParams } from "react-router-dom";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - Booking Page",
  description: "Luna - Booking Page",
};

export default function ProcessBookingPage() {
  const params = useParams();
  const bookingId = params.id;
  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 mb-20 overflow-y-scroll">
            <BookingForm bookingId={bookingId} />
          </div>
        </div>
      </div>
    </>
  );
}

export const ProcessBookingPageConfig: PageConfig = {
  path: "/bookings/:id/process",
  component: ProcessBookingPage,
  roles: ["admin", "root"],
};
