import { Role, User, UserRequest } from "../../interfaces/user.interface";
import http from "../axios";

export const me = () => {
  return http.get("/users/me");
};

export const getUsers = (
  param: UserRequest,
  abortController?: AbortController
) => {
  return http.get(`/admin/users`, {
    params: param,
    signal: abortController?.signal,
  });
};

export const addUsers = (user: {
  email: string;
  fullName: string;
  phone: string;
  role: Role;
  password: string;
  passwordConfirmation: string;
}) => {
  return http.post(`/admin/users`, user);
};

export const updateUsers = (
  id: string,
  user: {
    password?: string;
    passwordConfirmation?: string;
    email: string;
    fullName: string;
    phone: string;
  }
) => {
  return http.patch(`/admin/users/${id}`, user);
};

export const getUser = (id: string) => {
  return http.get(`/admin/users/${id}`);
};

export const getUserPassports = (
  id: string,
  q?: string,
  page: number = 1,
  limit: number = 10
) => {
  const query = [`page=${page}`, `limit=${limit}`];
  if (q !== undefined) {
    query.push(`q=${q}`);
  }
  return http.get(`/admin/users/${id}/passports?${query.join("&")}`);
};

export const addBalance = (
  id: string,
  amount: number,
  referenceId: string,
  remarks: string
) => {
  return http.post(`/admin/users/${id}/balance/add`, {
    amount,
    referenceId,
    remarks,
  });
};

export const deductBalance = (
  id: string,
  amount: number,
  referenceId: string,
  remarks: string
) => {
  return http.post(`/admin/users/${id}/balance/deduct`, {
    amount,
    referenceId,
    remarks,
  });
};

export const getWalletMutations = (
  id: string,
  page: number = 1,
  limit: number = 10
) => {
  const query = [`page=${page}`, `limit=${limit}`];
  return http.get(`/admin/users/${id}/wallet?${query.join("&")}`);
};
