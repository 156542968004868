import { Button, Form } from "react-bootstrap";
import { PageConfig } from "../../router";
import { ChangeEvent, useEffect, useState } from "react";
import { Setting } from "../../interfaces/setting.interface";
import { getSetting, updateSetting } from "../../utils/rest-api/settings";
import { toast } from "react-toastify";
import { useApp } from "../../contexts/AppContext";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - Setting Page",
  description: "Luna - Setting Page",
};

export default function SettingPage() {
  const [setting, setSetting] = useState<Setting>({
    invoiceDuration: undefined,
    transactionFee: undefined,
  });

  const [invalidSettingProps, setInvalidSettingProps] = useState({
    invoiceDuration: "",
    transactionFee: "",
  });

  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    showLoader();
    getSetting()
      .then((res) => {
        setSetting(res.data.data);
      })
      .finally(() => hideLoader());
  }, []);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting({ ...setting, [e.currentTarget.name]: e.currentTarget.value });
    setInvalidSettingProps({
      ...invalidSettingProps,
      [e.currentTarget.name]: "",
    });
  };

  const handleError = (err: any) => {
    if (err.response.data) {
      if (typeof err.response.data?.message == "object") {
        setInvalidSettingProps({
          ...invalidSettingProps,
          ...err.response.data.message,
        });
      } else {
        toast.error(err.response.data?.message ?? err.response.data.error);
      }
    } else {
      toast.error("Please check your network connection");
    }
  };

  const onSave = () => {
    updateSetting({
      transactionFee: Number(setting.transactionFee),
      invoiceDuration: Number(setting.invoiceDuration),
    })
      .then((res) => {
        toast.success("Settings successfully updated");
      })
      .catch((e) => {
        handleError(e);
      });
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="d-flex flex-row mb-20">
              <span className="text-30 lh-14 fw-600">Setting</span>
            </div>
            <div className="h-100" style={{ maxHeight: "calc(100% - 62px)" }}>
              <Form
                className="w-form-provider"
                onSubmit={(e) => e.preventDefault()}
              >
                <Form.Group className="mb-40">
                  <Form.Label>Transaction Fee</Form.Label>
                  <Form.Control
                    className={
                      invalidSettingProps.transactionFee && `border-error-2`
                    }
                    type="number"
                    name="transactionFee"
                    value={setting.transactionFee}
                    onChange={onInputChange}
                  />
                  <Form.Text className={`text-error-2`}>
                    {invalidSettingProps.transactionFee}
                  </Form.Text>
                </Form.Group>
                {/* <Form.Group className="mb-40">
                <Form.Label>Invoice Duration 	&#40;in seconds&#41;</Form.Label>
                <Form.Control className={invalidSettingProps.invoiceDuration && `border-error-2`} type="number" name="invoiceDuration" value={setting.invoiceDuration} onChange={onInputChange} />
                <Form.Text className={`text-error-2`}>{invalidSettingProps.invoiceDuration}</Form.Text>
              </Form.Group> */}
                <Button onClick={onSave} variant="primary" className="w-100">
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const SettingPageConfig: PageConfig = {
  path: "/settings",
  component: SettingPage,
  roles: ["admin", "root"],
};
