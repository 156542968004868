import { PageConfig } from "../../router";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { WalletMutation } from "../../interfaces/mutation.interface";
import WalletMutationTable from "../../components/dashboard/user/UserBalanceTable";
import { getWalletMutations } from "../../utils/rest-api/users";
import { toast } from "react-toastify";
import { useApp } from "../../contexts/AppContext";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - User's Wallet Page",
  description: "Luna - User's Wallet Page",
};

export default function ViewUserWalletMutationPage() {
  const params = useParams();
  const userId = params.id;
  const [mutations, setMutations] = useState<WalletMutation[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    showLoader();
    getWalletMutations(userId!)
      .then((res) => {
        const total = res.data.totalPages;
        setTotalPages(total);
        const data = res.data.data;
        setMutations(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => hideLoader());
  }, [userId, page]);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="mb-20">
              <span className="text-30 lh-14 fw-600">Wallet Mutations</span>
            </div>
            <WalletMutationTable
              data={mutations}
              className="h-100 md-overflow-y-visible overflow-y-scroll"
              total={totalPages}
              page={page}
              onPageChange={(page) => {
                setPage(page);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const ViewUserWalletMutationPageConfig: PageConfig = {
  path: "/users/:id/mutations",
  component: ViewUserWalletMutationPage,
  roles: ["admin", "root"],
};
