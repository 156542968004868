import Select, { GroupBase } from "react-select";
import { ReactElement } from "react";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

type ExtendedSelectType = <Option = unknown, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: StateManagerProps<Option, IsMulti, Group>) => ReactElement;
export const ExtendedSelect:ExtendedSelectType = (props) => {
    return <Select
      {...props}
      classNames={
        { 
          ...props.classNames
        }
      }
    />
}