import { FC, PropsWithChildren } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import { Provider } from "../../../interfaces/provider.interface";
import { Link, useNavigate } from "react-router-dom";

export interface ProviderTableProps extends PropsWithChildren {
  className?: string,
  page: number,
  total: number,
  data: Array<Provider>,
  onEdit: (provider:Provider) => void,
  onPageChange: (page:number) => void
}

const ProviderTable:FC<ProviderTableProps> = (props) => {
  return (
    <div className={props.className || ""}>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            props.data && props.data.length > 0 ? (
              props.data.map((dt, i)=> {
                return (
                  <tr key={`provider-${i}`}>
                    <td><Link className="text-blue-1 underline" to={"/schedules"} state={{provider:dt._id}}>{dt.name}</Link></td>
                    <td>{dt.phone}</td>
                    <td>{dt.email}</td>
                    <td>
                      <Badge bg={dt.isActive ? `success` : 'danger'}>{dt.isActive ? "Active" : "Inactive"}</Badge>
                    </td>
                    <td>
                      <Button variant="primary" onClick={(e) => props.onEdit(dt)}>Edit</Button>
                    </td>
                  </tr>
                )
              })
            )  : 
            <tr>
              <td className="text-center" colSpan={5}>No Data Available</td>
            </tr>
          }
        </tbody>
      </Table>
      <Pagination page={props.page} totalPage={props.total} onPageClick={(page)=>props.onPageChange(page)} />
    </div>
  );
};

export default ProviderTable;
