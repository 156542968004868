import { useState, type FormEvent } from "react";
import { Form, Button } from "react-bootstrap";
import { PageConfig } from "../../router";
import { login } from "../../utils/rest-api/auth";
import LoginForm from "../../components/common/LoginForm";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - Login Admin",
  description: "Luna - Login Admin",
};

const LoginPage = () => {
  return (
    <>
      <MetaComponent meta={metadata} />
      {/* End Page Title */}

      <section className="layout-pt-lg layout-pb-lg bg-blue-2 vh-100">
        <div className="container">
          <div className="row justify-center">
            <div className="col-xl-6 col-lg-7 col-md-9">
              <div className="px-50 py-50 sm:px-20 sm:py-20 bg-white shadow-4 rounded-4">
                <LoginForm />
                {/* End .Login */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End login section */}
    </>
  );
};
export default LoginPage;

export const LoginPageConfig:PageConfig = {
  path      : '/login',
  component : LoginPage,
  roles     : ["guest"]
}
