import { Button, Form } from "react-bootstrap";
import { PageConfig } from "../../router";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserTable from "../../components/dashboard/user/UserTable";
import { User } from "../../interfaces/user.interface";
import { getUsers } from "../../utils/rest-api/users";
import { useApp } from "../../contexts/AppContext";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - User Page",
  description: "Luna - User Page",
};

interface userFilterProps {
  q?: string;
  role: string;
  page: number;
}

export default function UserPage() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useApp();
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const roles = ["all", "root", "admin", "agent", "user"];

  const [userFilter, setUserFilter] = useState<userFilterProps>({
    role: "all",
    page: 1,
  });

  let timeoutId: MutableRefObject<NodeJS.Timeout | undefined> =
    useRef(undefined);

  const onQueryChange = (q?: string) => {
    setUserFilter((prev) => ({
      ...prev,
      q: q,
      page: 1,
    }));
    if (timeoutId && timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
    timeoutId.current = setTimeout(
      (queryValue) => {
        showLoader();
        getUsers({
          q: queryValue,
          role: userFilter.role == "all" ? undefined : [userFilter.role],
          page: 1,
          limit: 10,
        })
          .then((res) => {
            const total = res.data.totalPages;
            setTotalPages(total);
            setUsers(res.data.data);
          })
          .finally(() => hideLoader());
      },
      500,
      q
    );
  };

  const onRoleChange = (role: string) => {
    setUserFilter((prev) => ({
      ...prev,
      role: role,
      page: 1,
    }));
    showLoader();
    getUsers({
      q: userFilter.q,
      role: role == "all" ? undefined : [role],
      page: 1,
      limit: 10,
    })
      .then((res) => {
        const total = res.data.totalPages;
        setTotalPages(total);
        setUsers(res.data.data);
      })
      .finally(() => hideLoader());
  };

  const onPageChange = (page: number) => {
    setUserFilter((prev) => ({
      ...prev,
      page: page,
    }));
    showLoader();
    getUsers({
      q: userFilter.q,
      role: userFilter.role == "all" ? undefined : [userFilter.role],
      page: page,
      limit: 10,
    })
      .then((res) => {
        const total = res.data.totalPages;
        setTotalPages(total);
        setUsers(res.data.data);
      })
      .finally(() => hideLoader());
  };

  useEffect(() => {
    showLoader();
    getUsers({
      q: userFilter.q,
      role: userFilter.role == "all" ? undefined : [userFilter.role],
      page: userFilter.page,
      limit: 10,
    })
      .then((res) => {
        const total = res.data.totalPages;
        setTotalPages(total);
        setUsers(res.data.data);
      })
      .finally(() => hideLoader());
  }, []);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="d-flex flex-row mb-20">
              <span className="text-30 lh-14 fw-600 mr-10">User</span>
            </div>
            <div
              className="d-flex md:flex-column flex-row mb-20"
              style={{ gap: "5px" }}
            >
              <Form.Group
                className="w-filter-control"
                style={{ height: "100%" }}
              >
                <Form.Control
                  style={{ height: "100%" }}
                  type="text"
                  name="query"
                  value={userFilter.q}
                  onChange={(e) => {
                    onQueryChange(e.currentTarget.value);
                  }}
                  placeholder="Search"
                />
              </Form.Group>
              <Form.Select
                className="w-filter-control"
                style={{ height: "100%" }}
                value={userFilter.role}
                onChange={(e) => onRoleChange(e.currentTarget.value)}
              >
                {roles.map((role) => {
                  return (
                    <option key={`status-${role}`} value={role}>{`${role
                      .charAt(0)
                      .toUpperCase()}${role.slice(1)}`}</option>
                  );
                })}
              </Form.Select>
              <div className="flex-grow-1 md:d-none"></div>
              <Button variant="primary" onClick={(e) => navigate("/users/add")}>
                Add User
              </Button>
            </div>
            <UserTable
              className="h-100 md-overflow-y-visible overflow-y-scroll"
              data={users}
              total={totalPages}
              page={userFilter.page}
              onPageChange={onPageChange}
              onEdit={(user) => navigate(`/users/${user._id}`)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const UserPageConfig: PageConfig = {
  path: "/users",
  component: UserPage,
  roles: ["admin", "root"],
};
