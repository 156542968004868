import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../contexts/AppContext";
import { Booking, BookingUser } from "../../../interfaces/booking.interface";
import {
  getBooking,
  issueBooking,
  rejectPaidBooking,
} from "../../../utils/rest-api/bookings";
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { RateCategory } from "../common/Rates";
import { Ticket } from "../../../interfaces/ticket.interface";
import dayjs from "dayjs";
import { BookingStatus } from "../../../enums/booking.enum";
import { useAuth } from "../../../contexts/AuthContext";
import { parseErrorToObject } from "../../../utils/parseErrorToObject";
import {
  BookingActionButton,
  BookingActionModalProps,
} from "../../../pages/booking";

export interface BookingFormProps extends PropsWithChildren {
  className?: string;
  bookingId?: string;
  readonly?: boolean;
}

const BookingForm: FC<BookingFormProps> = (props) => {
  const [booking, setBooking] = useState<Booking>({
    _id: undefined,
    schedule: {
      provider: { _id: "", name: "" },
      origin: { _id: "", country: "", port: "" },
      destination: { _id: "", country: "", port: "" },
      departure: "",
    },
    tickets: [],
    status: "",
    createdAt: "",
    updatedAt: "",
    processedBy: {
      _id: "",
      email: "",
      role: "",
    },
    user: {
      _id: "",
      email: "",
      role: "",
    },
    totalPrice: 0,
    expiredAt: undefined,
    isTwoWay: false,
  });

  const navigate = useNavigate();
  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    if (props.bookingId) {
      showLoader();
      getBooking(props.bookingId)
        .then((res) => {
          setBooking(res.data.data);
        })
        .catch((e) => {
          toast.error(e.response.data.message ?? e.message);
        })
        .finally(() => hideLoader());
    }
  }, []);

  const { user } = useAuth();

  useEffect(() => {
    if (booking._id !== undefined && !props.readonly) {
      if (booking.status != BookingStatus.PROCESSING) {
        toast.error("You are not allowed to process this booking");
        navigate("/bookings");
      } else if ((booking.processedBy as BookingUser)._id != user?._id) {
        toast.error("This booking already processed by another admin");
        navigate("/bookings");
      }
    }
  }, [booking]);

  const [invalidTicketProps, setInvalidTicketProps] = useState<{
    tickets: Array<{ ticketCode: string }>;
  }>({
    tickets: [],
  });

  useEffect(() => {
    console.log(invalidTicketProps);
  }, [invalidTicketProps]);

  const getPassengers = () => {
    if (booking && booking.tickets) {
      const passengers = booking.tickets.reduce(
        (
          current: Array<{ category: RateCategory; count: number }>,
          prev: Ticket
        ) => {
          const data = current.find((dt) => dt.category == prev.rateCategory);
          data!.count += 1;
          return current;
        },
        [
          { category: RateCategory.ADULT, count: 0 },
          { category: RateCategory.CHILD, count: 0 },
          { category: RateCategory.INFANT, count: 0 },
        ]
      );
      return passengers
        .map((dt) => {
          return dt.count + " " + dt.category;
        })
        .join(", ");
    }
    return "";
  };

  const onTicketCodeChange = (ticket: Ticket, value: string) => {
    ticket.ticketCode = value;
    setBooking({ ...booking });
  };

  const onProcess = () => {
    const ticketsPayload = booking.tickets.map((ticket) => {
      return {
        ticketId: ticket._id,
        ticketCode: ticket.ticketCode,
      };
    });
    showLoader();
    issueBooking(booking._id!, ticketsPayload)
      .then((res) => {
        toast.success("Ticket successfully issued");
        navigate("/bookings");
      })
      .catch((e) => {
        handleError(e);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const [modal, setModal] = useState<BookingActionModalProps>({
    show: false,
    action: undefined,
    booking: undefined,
    reason: "",
  });
  const renderModalTitle = () => {
    switch (modal.action) {
      case BookingActionButton.APPROVE_CANCELLATION:
        return "Approve Cancellation Request";
      case BookingActionButton.REJECT_CANCELLATION:
        return "Reject Cancellation Request";
      case BookingActionButton.REJECT_PAID:
        return "Reject Paid Booking";
    }
  };

  const closeModal = () => {
    setModal({
      show: false,
      action: undefined,
      booking: undefined,
      reason: "",
    });
  };

  const onModalProcessButtonClick = () => {
    switch (modal.action) {
      case BookingActionButton.REJECT_PAID:
        showLoader();
        rejectPaidBooking(modal.booking?._id!, modal.reason)
          .then((res) => {
            toast.success("Booking successfuly rejected!");
            closeModal();
            // fetchData();
            navigate("/bookings");
          })
          .catch((e) => {
            if (e.response.status == 400) {
              setModal({
                ...modal,
                invalidReason: e.response.data?.message?.reason,
              });
            } else {
              toast.error(
                e.response?.data.message || e.response?.error || "Network error"
              );
            }
          })
          .finally(() => {
            hideLoader();
          });
        break;
      default:
        break;
    }
  };

  const handleError = (err: any) => {
    if (err.response.data) {
      if (typeof err.response.data?.message == "object") {
        const data: any = parseErrorToObject(err.response.data.message);
        setInvalidTicketProps(data);
      } else {
        toast.error(err.response.data?.message ?? err.response.data.error);
      }
    } else {
      toast.error("Please check your network connection");
    }
  };

  return (
    <div className={`ps-2 pe-2 pb-30 ${props.className}`}>
      <div className="d-flex flex-row md:flex-column justify-content-between">
        <div className="mb-20">
          <span className="text-30 lh-14 fw-600">
            {props.readonly ? "View" : "Process"}&nbsp;Booking
          </span>
        </div>

        {booking._id && booking.status == BookingStatus.ISSUED && (
          <Button
            variant="primary"
            className="w-sm-100"
            onClick={(e) => {
              navigate(`/bookings/${booking._id}/itineray`);
            }}
          >
            <div className="d-flex flex-row gap-1 justify-content-center w-20 h-20">
              <img src="/img/icons/downloadIcon.svg" alt="image" />
              <div>Booking Details</div>
            </div>
          </Button>
        )}
      </div>
      <Form onSubmit={(e) => e.preventDefault()}>
        <InputGroup className="mb-2 d-flex flex-row gap-2">
          <Form.Group className="w-50 w-sm-100">
            <Form.Label>Booking ID</Form.Label>
            <Form.Control readOnly disabled value={booking._id} />
          </Form.Group>
          <Form.Group className="flex-grow-1">
            <Form.Label>Status</Form.Label>
            <Form.Control
              readOnly
              disabled
              value={booking.status
                .split("_")
                .map((dt) => dt.charAt(0).toUpperCase() + dt.slice(1))
                .join(" ")}
            />
          </Form.Group>
        </InputGroup>
        <InputGroup className="mb-2 d-flex flex-row gap-2">
          <Form.Group className="w-50 w-sm-100">
            <Form.Label>Provider</Form.Label>
            <Form.Control
              readOnly
              disabled
              value={booking.schedule.provider.name}
            />
          </Form.Group>
          <Form.Group className="flex-grow-1">
            <Form.Label>Route</Form.Label>
            <Form.Control
              readOnly
              disabled
              value={`${booking.schedule.origin.country} ${String.fromCharCode(
                8594
              )} ${booking.schedule.destination.country}`}
            />
          </Form.Group>
        </InputGroup>
        <InputGroup className="mb-2 d-flex flex-row gap-2">
          <Form.Group className="w-50 w-sm-100">
            <Form.Label>Total Passengers</Form.Label>
            <Form.Control readOnly disabled value={getPassengers()} />
          </Form.Group>
          <Form.Group className="flex-grow-1">
            <Form.Label>Total Amount</Form.Label>
            <Form.Control readOnly disabled value={booking.totalPrice} />
          </Form.Group>
        </InputGroup>
        <Form.Group className="w-50 w-sm-100 mb-20">
          <Form.Label>Expired At</Form.Label>
          <Form.Control
            readOnly
            disabled
            value={dayjs(booking.expiredAt).format("DD-MMM-YYYY HH:mm:ss")}
          />
        </Form.Group>
        <h5 className="mb-20">Tickets</h5>
        <div className="d-flex flex-row md:flex-column gap-2">
          {booking.tickets.map((ticket, idx) => {
            return (
              <Card
                bg={"light"}
                key={"light"}
                text={"black"}
                style={{ width: "18rem" }}
                className="mb-2 w-md-100"
              >
                <Card.Header className="text-22">
                  {ticket.fullName} - {ticket.passportNumber}
                </Card.Header>
                <Card.Body>
                  <Card.Text className="mb-2">
                    Gender:{" "}
                    {ticket.gender[0].toUpperCase() + ticket.gender.slice(1)}
                  </Card.Text>
                  <Card.Text className="mb-2">
                    Date of birth: {ticket.dateOfBirth}
                  </Card.Text>
                  <Card.Text className="mb-2">
                    Nationality: {ticket.nationality}
                  </Card.Text>
                  <Card.Text className="mb-2">
                    Issuing Country: {ticket.issuingCountry}
                  </Card.Text>
                  <Card.Text className="mb-2">
                    Issuing date: {ticket.issuingDate}
                  </Card.Text>
                  <Card.Text className="mb-2">
                    Expired date: {ticket.expiredDate}
                  </Card.Text>
                  <Card.Text className="mb-2">
                    Rate Category: {ticket.rateCategory}
                  </Card.Text>
                  <Card.Text className="mb-2">Price: {ticket.price}</Card.Text>
                  {props.readonly ? (
                    <Card.Text>
                      Ticket Code:{" "}
                      {ticket.ticketCode ? (
                        <Badge bg="success">{ticket.ticketCode}</Badge>
                      ) : (
                        <Badge bg="danger">not processed</Badge>
                      )}
                    </Card.Text>
                  ) : (
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Ticket Code"
                        value={ticket.ticketCode}
                        onChange={(e) =>
                          onTicketCodeChange(ticket, e.currentTarget.value)
                        }
                        className={
                          invalidTicketProps?.tickets[idx]?.ticketCode &&
                          `border-error-2`
                        }
                      />
                      <Form.Text className={`text-error-2`}>
                        {invalidTicketProps?.tickets[idx]?.ticketCode}
                      </Form.Text>
                    </Form.Group>
                  )}
                </Card.Body>
              </Card>
            );
          })}
        </div>
        {!props.readonly && (
          <div className="mb-2 d-flex flex-row gap-2">
            <Button
              onClick={(e) =>
                setModal({
                  show: true,
                  action: BookingActionButton.REJECT_PAID,
                  booking: booking,
                  reason: "",
                })
              }
              variant="danger"
              className="flex-grow-1"
            >
              Reject
            </Button>
            <Button
              onClick={onProcess}
              variant="primary"
              className="w-50 w-sm-100"
            >
              Process
            </Button>
          </div>
        )}
      </Form>
      <Modal
        show={modal.show}
        onHide={() =>
          setModal({
            show: false,
            action: undefined,
            booking: undefined,
            reason: "",
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{renderModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Are you sure want to process this action?</span>
          {(modal.action == BookingActionButton.REJECT_CANCELLATION ||
            modal.action == BookingActionButton.REJECT_PAID) && (
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Reason</Form.Label>
              <Form.Control
                className={modal.invalidReason && `border-error-2`}
                style={{ resize: "none" }}
                as="textarea"
                rows={3}
                value={modal.reason}
                onChange={(e) => {
                  setModal({ ...modal, reason: e.currentTarget.value });
                }}
              />
              <Form.Text className={`text-error-2`}>
                {modal.invalidReason}
              </Form.Text>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) =>
              setModal({
                show: false,
                action: undefined,
                booking: undefined,
                reason: "",
              })
            }
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onModalProcessButtonClick();
            }}
          >
            Process
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookingForm;
