import {
  ReportWeeklyRequest,
  ReportsBookingsRequest,
} from "../../interfaces/reports.interface";
import http from "../axios";

export const getSummary = () => {
  return http.get("/admin/reports/dashboard/summary");
};

export const getWeekly = (params: ReportWeeklyRequest) => {
  return http.get("/admin/reports/dashboard/weekly", { params: params });
};

export const getReportsBookings = (params: ReportsBookingsRequest) => {
  return http.get("/admin/reports/bookings", { params: params });
};
