import { FC, PropsWithChildren, useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import dayjs from "dayjs";
import {
  Schedule,
  ScheduleProvider,
} from "../../../interfaces/schedule.interface";
import { ScheduleStatus } from "../../../enums/schedule.enum";
import { useNavigate } from "react-router-dom";

export interface ScheduleTableProps extends PropsWithChildren {
  className?: string;
  data: Schedule[];
  page: number;
  total: number;
  onEdit: (schedule: Schedule) => void;
  onPageChange: (page: number) => void;
  onSaveStatusChange: (schedule: Schedule) => void;
}

const ScheduleTable: FC<ScheduleTableProps> = (props) => {
  const [schedules, setSchedule] = useState<Schedule[]>(
    JSON.parse(JSON.stringify(props.data))
  );

  const onScheduleStatusChange = (index: number, value: ScheduleStatus) => {
    const scheduleToEdit = [...schedules];
    scheduleToEdit[index].status = value;
    setSchedule(scheduleToEdit);
  };

  useEffect(() => {
    setSchedule(JSON.parse(JSON.stringify(props.data)));
  }, [props.data]);

  const statuses = [
    ScheduleStatus.PENDING,
    ScheduleStatus.OPEN,
    ScheduleStatus.CLOSED,
    ScheduleStatus.EXPIRED,
    ScheduleStatus.FULL,
  ];

  return (
    <div className={props.className || ""}>
      <Table striped>
        <thead>
          <tr>
            <th>Provider</th>
            <th>Departure</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
          props.data.length > 0 &&
          schedules.length > 0 &&
          props.data.length == schedules.length ? (
            props.data.map((dt, i) => {
              return (
                <tr key={`schedule-${i}`}>
                  <td>{(dt.provider as ScheduleProvider).name}</td>
                  <td>{dayjs(dt.departure).format("HH:mm DD-MMM-YYYY")}</td>
                  <td>
                    <Form.Select
                      aria-label="Origin"
                      value={schedules[i].status}
                      onChange={(e) =>
                        onScheduleStatusChange(
                          i,
                          e.currentTarget.value as ScheduleStatus
                        )
                      }
                    >
                      {statuses.map((status) => {
                        return (
                          <option
                            key={`status-${status}`}
                            disabled={
                              [
                                ScheduleStatus.EXPIRED,
                                ScheduleStatus.FULL,
                              ].indexOf(status) >= 0
                            }
                            value={status}
                          >{`${status.charAt(0).toUpperCase()}${status.slice(
                            1
                          )}`}</option>
                        );
                      })}
                    </Form.Select>
                  </td>
                  <td>
                    {dt.status != schedules[i].status ? (
                      <Button
                        variant="primary"
                        onClick={(e) => props.onSaveStatusChange(schedules[i])}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={(e) => props.onEdit(schedules[i])}
                      >
                        Edit
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="text-center" colSpan={5}>
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination
        page={props.page}
        totalPage={props.total}
        onPageClick={(page) => props.onPageChange(page)}
      />
    </div>
  );
};

export default ScheduleTable;
