import MetaComponent from "../../components/common/MetaComponent";
import NotFound from "../../components/common/NotFound";
import { PageConfig } from "../../router";

const metadata = {
  title: "Luna - 404 Not Found",
  description: "Luna - 404 Not Found",
};

const NotFound404Page = () => {
  return (
    <>
      <MetaComponent meta={metadata} />
      {/* End Page Title */}

      <NotFound />
      {/* End 404 section */}
    </>
  );
};

export default NotFound404Page;

export const NotFound404PageConfig:PageConfig = {
  path      : '/*',
  component : NotFound404Page,
  roles     : ["guest", "admin", "root"]
}