import http from "../axios";

export const getBalances = (
  role: string,
  abortController?: AbortController
) => {
  const param = { role: role };
  return http.get(`/admin/users/balances`, {
    params: param,
    signal: abortController?.signal,
  });
};
