import { TripRequest } from "../../interfaces/trip.interface";
import http from "../axios";

export const getTrips = (
  param: TripRequest,
  abortController?: AbortController
) => {
  return http.get("/admin/terminal/trips", {
    params: param,
    signal: abortController?.signal,
  });
};
