import Aos from "aos";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";
import "./styles/index.scss";
import "aos/dist/aos.css";
import ScrollTop from "./components/common/ScrollTop";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import ScrollTopBehaviour from "./components/common/ScrollTopBehaviour";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { dashboardPages, nonDashboardAppPages, publicAppPages } from "./router";
import DashboardLayout from "./components/dashboard/dashboard/DashboardLayout";
import Loader from "./components/common/Loader";

if (typeof window !== "undefined") {
  import("bootstrap");
}

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <AppProvider>
              <Routes>
                <Route path="/" element={<DashboardLayout />}>
                  {dashboardPages.map((page, i) => (
                    <Route key={i} path={page.path} Component={page.component} />
                  ))}
                </Route>
                {nonDashboardAppPages.map((page, i) => (
                  <Route key={i} path={page.path} Component={page.component} />
                ))}
                {publicAppPages.map((page, i) => (
                  <Route key={i} path={page.path} Component={page.component} />
                ))}
              </Routes>
              <ScrollTopBehaviour />
            <ToastContainer />
            <Loader />
          <ScrollTop />
        </AppProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
