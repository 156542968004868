import { LocationType } from "../../enums/location-type.enum";
import { Location } from "../../interfaces/location.interface";
import http from "../axios";

export const getLocations = (type?: LocationType) => {
  const query = [];
  if (type !== undefined) {
    query.push(`type=${type}`);
  }
  return http.get(`/admin/locations?${query.join("&")}`);
};

export const addLocation = (location: Location) => {
  return http.post(`/admin/locations`, {
    code: location.code,
    country: location.country,
    port: location.port,
    address: location.address,
    type: location.type,
    isActive: location.isActive,
    timezone: location.timezone,
  });
};

export const updateLocation = (id: string, location: Location) => {
  return http.put(`/admin/locations/${id}`, {
    code: location.code,
    country: location.country,
    port: location.port,
    address: location.address,
    type: location.type,
    isActive: location.isActive,
    timezone: location.timezone,
  });
};

export const getLocation = (id: string) => {
  return http.get(`/admin/locations/${id}`);
};
