import React, {
  FC,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import {
  ActionMeta,
  InputActionMeta,
  MultiValue,
  SingleValue,
} from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";
import Rates, { Rate, RateCategory, RateType } from "../common/Rates";
import {
  Departure,
  Schedule,
  ScheduleProvider,
} from "../../../interfaces/schedule.interface";
import { ScheduleStatus } from "../../../enums/schedule.enum";
import { getProviders } from "../../../utils/rest-api/providers";
import { DateObject } from "react-multi-date-picker";
import {
  addSchedule,
  getSchedule,
  updateSchedule,
} from "../../../utils/rest-api/schedules";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { parseErrorToObject } from "../../../utils/parseErrorToObject";
import { getLocations } from "../../../utils/rest-api/locations";
import { Location } from "../../../interfaces/location.interface";
import classNames from "classnames";
import { ExtendedDatePicker } from "../../common/ExtendedDatePicker";
import { ExtendedSelect } from "../../common/ExtendedSelect";
import { useApp } from "../../../contexts/AppContext";
import dayjs from "dayjs";
import {
  Trip,
  TripRequest,
  TripResponse,
} from "../../../interfaces/trip.interface";
import { getTrips } from "../../../utils/rest-api/trips";
import { AxiosResponse } from "axios";
import ReactDOM from "react-dom";
import {
  addSeperator,
  removeNonNumeric,
} from "../../../utils/currencyValidator";
import { detectOverflow } from "@popperjs/core";

const statuses = [
  ScheduleStatus.PENDING,
  ScheduleStatus.OPEN,
  ScheduleStatus.CLOSED,
  ScheduleStatus.EXPIRED,
  ScheduleStatus.FULL,
];

export interface ScheduleFormProps extends PropsWithChildren {
  className?: string;
  scheduleId?: string;
}

const ScheduleForm: FC<ScheduleFormProps> = (props) => {
  const [schedule, setSchedule] = useState<Schedule>({
    _id: "",
    provider: {
      _id: "",
      name: "",
    },
    origin: "",
    destination: "",
    rates: [],
    status: ScheduleStatus.PENDING,
    createdAt: new Date(),
    updatedAt: new Date(),
    by: "",
    cancellationFee: 0,
  });

  const [states, setStates] = useState<Location[]>([]);

  const [invalidScheduleProps, setInvalidScheduleProps] = useState({
    provider: "",
    origin: "",
    destination: "",
    rates: [],
    status: "",
    departure: "",
    cancellationFee: "",
  });

  const navigate = useNavigate();

  const onDepartureDateChange = (dates: DateObject | DateObject[] | null) => {
    setInvalidScheduleProps({ ...invalidScheduleProps, departure: "" });
    if (dates == null) {
      setDepartures([]);
    } else if (Array.isArray(dates)) {
      let departures: Departure[] = dates.map((dt) => {
        let date = dt.setSecond(0).toDate();
        let departure = dayjs(date).format("YYYY-MM-DDTHH:mm");
        return {
          departure: departure,
        };
      });
      setDepartures(departures);
    } else {
      let date = dates.toDate();
      let departure = dayjs(date).format("YYYY-MM-DDTHH:mm");
      setDepartures([{ departure: departure }]);
    }
  };
  const [departures, setDepartures] = useState<Array<Departure>>([]);

  const defaultAgentRates: Array<Rate> = [
    {
      category: RateCategory.ADULT,
      basePrice: 0,
      margin: 0,
      twoWay: false,
      type: RateType.USER,
      isForeigner: false,
    },
    {
      category: RateCategory.CHILD,
      basePrice: 0,
      margin: 0,
      twoWay: false,
      type: RateType.USER,
      isForeigner: false,
    },
    {
      category: RateCategory.INFANT,
      basePrice: 0,
      margin: 0,
      twoWay: false,
      type: RateType.USER,
      isForeigner: false,
    },
  ];

  const [options, setOptions] = useState({
    diferForeigner: false,
    hasTwoWay: false,
    hasAgentPrice: false,
  });

  let [ratesGrouping, setRatesGrouping] = useState<
    Array<{
      type: RateType;
      rates: Array<Rate>;
    }>
  >([]);

  useEffect(() => {
    const data = schedule.rates!.reduce<
      Array<{
        type: RateType;
        rates: Array<Rate>;
      }>
    >((prev, current, index) => {
      let data = prev.find((dt) => dt.type == current.type);
      if (!data) {
        data = {
          type: current.type,
          rates: [],
        };
        prev.push(data);
      }
      data.rates.push(current);
      return prev;
    }, []);
    setRatesGrouping(data);
  }, [schedule]);

  const onRateChanges = (
    changedRate: Rate,
    name: "basePrice" | "margin",
    value: string
  ) => {
    const scheduleToSet = { ...schedule };
    const rateIndex = Number(
      scheduleToSet.rates?.findIndex((rate) => rate == changedRate)
    );
    const rate = scheduleToSet.rates![rateIndex];

    // MARK: - 0 as default
    const IntValue = parseInt(value);
    changedRate[name] = IntValue ? IntValue : 0;
    const basePriceChanged = name == "basePrice";
    const marginChanged = name == "margin";

    const basePrice = changedRate.basePrice
      ? parseInt(changedRate.basePrice?.toString())
      : 0;
    const margin = changedRate.margin
      ? parseInt(changedRate.margin?.toString())
      : 0;

    if (typeof basePrice == "number") {
      rate.basePrice = basePrice;
    }

    if (typeof margin == "number") {
      rate.margin = margin;
    }

    const invalidSchedulePropsToSet = { ...invalidScheduleProps };
    const invalidRateToSet: any = invalidSchedulePropsToSet?.rates![rateIndex];

    if (invalidRateToSet) {
      if (basePriceChanged) {
        invalidRateToSet.basePrice = "";
      }
      if (marginChanged) {
        invalidRateToSet.margin = "";
      }
      setInvalidScheduleProps(invalidSchedulePropsToSet);
    }
    setSchedule(scheduleToSet);
  };

  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    const promises = [];
    promises.push(
      getLocations()
        .then((res) => {
          setStates(res.data.data);
        })
        .catch((e) => {})
    );
    promises.push(
      getProviders(undefined, true, 1, 5)
        .then((res) => {
          setProviders(res.data.data);
        })
        .catch((e) => {})
    );

    if (props.scheduleId) {
      promises.push(
        getSchedule(props.scheduleId).then((res) => {
          const data = res.data.data;
          const hasTwoWay =
            data.rates.find((rate: Rate) => rate.twoWay) !== undefined;
          const hasAgentPrice =
            data.rates.find((rate: Rate) => rate.type == RateType.AGENT) !==
            undefined;
          const diferForeigner =
            data.rates.find((rate: Rate) => rate.isForeigner) !== undefined;
          data.origin = data.origin._id;
          data.destination = data.destination._id;
          setOptions({
            hasTwoWay: hasTwoWay,
            diferForeigner: diferForeigner,
            hasAgentPrice: hasAgentPrice,
          });

          let date = new Date(data.departure);
          let departure = dayjs(date).format("YYYY-MM-DDTHH:mm");
          setDepartures([{ departure: departure }]);
          setSchedule(data);
        })
      );
    } else {
      setOptions({
        hasTwoWay: false,
        diferForeigner: false,
        hasAgentPrice: false,
      });
      setSchedule({ ...schedule, rates: defaultAgentRates });
    }
    showLoader();
    Promise.all(promises).finally(() => hideLoader());
  }, []);

  const ratesComponent = (type: RateType, rates: Array<Rate>) => {
    const rateComponentSources = rates.map((rate, index) => {
      const basePrice =
        invalidScheduleProps.rates?.length > index
          ? (invalidScheduleProps.rates[index] as any).basePrice
          : "";
      const margin =
        invalidScheduleProps.rates?.length > index
          ? (invalidScheduleProps.rates[index] as any).margin
          : "";
      return {
        rate,
        error: {
          basePrice,
          margin,
        },
      };
    });
    return (
      <div>
        <div className="d-flex flex-row mb-5">
          <React.Fragment>
            <h5
              style={{
                marginBottom: "0px",
                marginTop: "2px",
              }}
              className="h5 text-decoration-underline mr-15 mb-5"
            >
              {type == RateType.AGENT ? "Agent" : "Default"}
            </h5>
          </React.Fragment>
        </div>
        <Rates onChange={onRateChanges} sources={rateComponentSources} />
      </div>
    );
  };

  const [providers, setProviders] = useState<ScheduleProvider[]>([]);

  let providerTimeoutId: MutableRefObject<NodeJS.Timeout | undefined> =
    useRef(undefined);
  const onProviderInputChange = (value: string, action: InputActionMeta) => {
    setInvalidScheduleProps({ ...invalidScheduleProps, provider: "" });
    if (providerTimeoutId && providerTimeoutId.current) {
      clearTimeout(providerTimeoutId.current);
      providerTimeoutId.current = undefined;
    }
    if (action.action == "input-change") {
      providerTimeoutId.current = setTimeout(() => {
        getProviders(value, true, 1, 5)
          .then((res) => {
            setProviders(res.data.data);
          })
          .catch((e) => {});
        providerTimeoutId.current = undefined;
      }, 1000);
    }
  };

  const handleError = (err: any) => {
    if (err.response.data) {
      if (typeof err.response.data?.message == "object") {
        const data: any = parseErrorToObject(err.response.data.message);
        setInvalidScheduleProps({
          ...data.schedule,
          departure: data.departures,
        });
      } else {
        toast.error(err.response.data?.message ?? err.response.data.error);
      }
    } else {
      toast.error("Please check your network connection");
    }
  };

  const onScheduleSave = () => {
    if (props.scheduleId) {
      showLoader();
      updateSchedule(props.scheduleId, {
        provider: (schedule.provider as ScheduleProvider)._id,
        origin: schedule.origin,
        destination: schedule.destination,
        rates: schedule.rates,
        cancellationFee: schedule.cancellationFee,
        status: schedule.status,
      })
        .then((res) => {
          toast.success("Schedules successfully updated");
          navigate("/schedules");
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => hideLoader());
    } else {
      showLoader();
      addSchedule({
        departures: departures,
        schedule: {
          provider: (schedule.provider as ScheduleProvider)._id,
          origin: schedule.origin,
          destination: schedule.destination,
          rates: schedule.rates,
          cancellationFee: schedule.cancellationFee,
          status: schedule.status,
        },
      })
        .then((res) => {
          toast.success("Schedules successfully added");
          // navigate("/schedules");
          setTripProps({
            show: false,
            date: undefined,
            errorMessage: undefined,
            trip: undefined,
            tempTrip: undefined,
          });
          setSchedule({
            ...schedule,
            provider: "",
            origin: "",
            destination: "",
          });
          setDepartures([]);
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => hideLoader());
    }
  };

  const onScheduleFormChange = (name: string, value: any) => {
    setSchedule({ ...schedule, [name]: value });
    setInvalidScheduleProps({ ...invalidScheduleProps, [name]: "" });
  };

  const OnSwitchChanged = (type: string) => {
    switch (type) {
      case "difer-foreigner":
        const hasForeigner = !options.diferForeigner;
        if (!hasForeigner) {
          const rates = schedule.rates!.filter((rate) => {
            return rate.isForeigner === false;
          });
          setSchedule({ ...schedule, rates: rates });
        } else {
          const rates = schedule.rates!.filter((rate) => {
            return rate.isForeigner === false;
          });
          const ratesToAdd = [...rates];
          for (let i = 0; i < rates.length; i++) {
            ratesToAdd.push({ ...rates[i], isForeigner: true });
          }
          setSchedule({ ...schedule, rates: ratesToAdd });
        }
        setOptions({ ...options, diferForeigner: !options.diferForeigner });
        break;
      case "has-two-way":
        const hasTwoWay = !options.hasTwoWay;
        if (!hasTwoWay) {
          const rates = schedule.rates!.filter((rate) => {
            return rate.twoWay === false;
          });
          setSchedule({ ...schedule, rates: rates });
        } else {
          const rates = schedule.rates!.filter((rate) => {
            return rate.twoWay === false;
          });
          const ratesToAdd = [...rates];
          for (let i = 0; i < rates.length; i++) {
            ratesToAdd.push({ ...rates[i], twoWay: true });
          }
          setSchedule({ ...schedule, rates: ratesToAdd });
        }
        setOptions({ ...options, hasTwoWay: !options.hasTwoWay });
        break;
      case "has-agent-price":
        const hasAgentPrice = !options.hasAgentPrice;
        if (!hasAgentPrice) {
          const rates = schedule.rates!.filter((rate) => {
            return rate.type == RateType.USER;
          });
          setSchedule({ ...schedule, rates: rates });
        } else {
          const rates = schedule.rates!.filter((rate) => {
            return rate.type == RateType.USER;
          });
          const ratesToAdd = [...rates];
          for (let i = 0; i < rates.length; i++) {
            ratesToAdd.push({ ...rates[i], type: RateType.AGENT });
          }
          setSchedule({ ...schedule, rates: ratesToAdd });
        }
        setOptions({ ...options, hasAgentPrice: !options.hasAgentPrice });
        break;
    }
  };

  interface TripProps {
    show: boolean;
    date?: DateObject;
    errorMessage?: string;
    trip?: Trip[];
    tempTrip?: Trip[];
  }

  const [tripProps, setTripProps] = useState<TripProps>({
    show: false,
    date: undefined,
    errorMessage: undefined,
    trip: undefined,
    tempTrip: undefined,
  });

  const onTripChange = (value: DateObject) => {
    setTripProps((prev) => ({ ...prev, date: value }));
  };

  const onTripSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (tripProps.date) {
      setTripProps({
        show: true,
        date: tripProps.date,
        trip: tripProps.trip,
        tempTrip: undefined,
      });
    } else {
      setTripProps((prev) => ({
        ...prev,
        show: false,
        errorMessage: "Please Select Date",
      }));
    }
  };

  const onTripSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setTripProps({
      show: false,
      date: tripProps.date,
      errorMessage: undefined,
      trip: tripProps.tempTrip,
      tempTrip: undefined,
    });

    if (tripProps.tempTrip) {
      setSchedule({
        ...schedule,
        provider: tripProps.tempTrip?.at(0)?.provider,
        origin: tripProps.tempTrip?.at(0)?.origin._id,
        destination: tripProps.tempTrip?.at(0)?.destination._id,
      });

      let departures: Departure[] = tripProps.tempTrip.map((dt) => {
        let date = new Date(Date.parse(`${dt.date} ${dt.time}`));
        let departure = dayjs(date).format("YYYY-MM-DDTHH:mm");

        return { departure: departure, tripId: dt.tripId };
      });

      setDepartures(departures);

      setInvalidScheduleProps({
        ...invalidScheduleProps,
        provider: "",
        origin: "",
        destination: "",
      });
    } else {
      setSchedule({
        ...schedule,
        provider: "",
        origin: "",
        destination: "",
      });

      setDepartures([]);

      setInvalidScheduleProps({
        ...invalidScheduleProps,
        provider: "",
        origin: "",
        destination: "",
      });
    }
  };

  const animatedComponents = makeAnimated();
  let abortController: AbortController | undefined = undefined;
  let terminalTimeoutId: NodeJS.Timeout | undefined = undefined;

  const loadOptions = (
    inputValue: string,
    callback: (options: Trip[]) => void
  ) => {
    if (terminalTimeoutId) {
      clearTimeout(terminalTimeoutId);
      terminalTimeoutId = undefined;
    }

    terminalTimeoutId = setTimeout(() => {
      abortController = new AbortController();
      if (!tripProps.date) return;
      getTrips({ date: tripProps.date.format("YYYY-MM-DD") }, abortController)
        .then((res: AxiosResponse<TripResponse>) => {
          if (abortController) callback(res.data.data);
        })
        .catch((e) => {
          toast.error(
            e.response?.data?.message ??
              e.response?.data?.error ??
              e.message ??
              "Internal server error, please try again in a few minutes"
          );
          callback([]);
        })
        .finally(() => {
          abortController = undefined;
        });
      terminalTimeoutId = undefined;
    }, 1000);
  };

  return (
    <div
      id="schedule-form-scroll-container"
      className={`ps-2 pe-2 pb-30 ${props.className}`}
    >
      <div className="mb-20">
        <span className="text-30 lh-14 fw-600">
          {props.scheduleId ? "Edit" : "Add"} Schedule
        </span>
      </div>
      <Form onSubmit={(e) => e.preventDefault()}>
        {!props.scheduleId && (
          <React.Fragment>
            {/* Select */}
            <InputGroup className="mb-2 d-flex flex-row gap-2">
              <Form.Group className="w-100">
                <Form.Label>Select Schedule from Terminal</Form.Label>
                <div className="d-flex flex-row gap-2">
                  <div className="w-50 w-sm-100">
                    <ExtendedDatePicker
                      format="DD/MM/YYYY"
                      minDate={new Date()}
                      value={tripProps.date}
                      onChange={onTripChange}
                      inputClass={`${
                        tripProps.errorMessage && `border-error-2`
                      }`}
                      containerClassName="datetimepicker_wrapper"
                      calendarPosition="bottom-center"
                      fixMainPosition={true}
                    />
                  </div>
                  <Button
                    onClick={onTripSearch}
                    variant="primary"
                    className="flex-grow-1 w-140"
                    type="submit"
                  >
                    Search
                  </Button>
                </div>

                <Form.Text className={`text-error-2`}>
                  {tripProps.errorMessage}
                </Form.Text>
              </Form.Group>
            </InputGroup>
          </React.Fragment>
        )}
        <hr />
        {/* Form */}
        <InputGroup className="mb-2 d-flex flex-row gap-2">
          <Form.Group className="w-50 w-sm-100">
            <Form.Label>Provider</Form.Label>
            <ExtendedSelect
              classNames={{
                control: ({ isFocused }) =>
                  classNames(isFocused && "react-select-focus"),
              }}
              aria-label="Origin"
              getOptionLabel={(provider: ScheduleProvider) => provider.name}
              getOptionValue={(provider: ScheduleProvider) => provider._id!}
              options={providers}
              value={schedule.provider as ScheduleProvider}
              onInputChange={onProviderInputChange}
              onChange={(
                provider: SingleValue<ScheduleProvider>,
                action: ActionMeta<ScheduleProvider>
              ) => {
                setSchedule({
                  ...schedule,
                  provider: {
                    _id: provider?._id!,
                    name: provider?.name!,
                  },
                });
                setInvalidScheduleProps({
                  ...invalidScheduleProps,
                  provider: "",
                });
              }}
              isDisabled={tripProps.trip ? true : false || !!props.scheduleId}
            />
            <Form.Text className={`text-error-2`}>
              {invalidScheduleProps.provider}
            </Form.Text>
          </Form.Group>
          <Form.Group className="flex-grow-1">
            <Form.Label>Status</Form.Label>
            <Form.Select
              className={invalidScheduleProps.status && `border-error-2`}
              aria-label="Status"
              style={{ height: "44px" }}
              value={schedule.status}
              onChange={(e) =>
                onScheduleFormChange("status", e.currentTarget.value)
              }
              // disabled={!!props.scheduleId}
            >
              {statuses.map((status) => {
                return (
                  <option
                    key={`status-${status}`}
                    disabled={
                      [ScheduleStatus.EXPIRED, ScheduleStatus.FULL].indexOf(
                        status
                      ) >= 0
                    }
                    value={status}
                  >{`${status.charAt(0).toUpperCase()}${status.slice(
                    1
                  )}`}</option>
                );
              })}
            </Form.Select>
            <Form.Text className={`text-error-2`}>
              {invalidScheduleProps.status}
            </Form.Text>
          </Form.Group>
        </InputGroup>
        <InputGroup className="mb-3 flex flex-row gap-2">
          <Form.Group
            className="flex-grow-1"
            style={{ width: "calc((100% - 10px) / 2)" }}
          >
            <Form.Label>Origin</Form.Label>
            <Form.Select
              className={invalidScheduleProps.origin && `border-error-2`}
              style={{ height: "44px" }}
              aria-label="Origin"
              value={schedule.origin}
              onChange={(e) =>
                onScheduleFormChange("origin", e.currentTarget.value)
              }
              disabled={tripProps.trip ? true : false || !!props.scheduleId}
            >
              <option hidden></option>
              {states
                .filter((state) => state.type == "origin")
                .map((state) => (
                  <option key={state._id} value={`${state._id}`}>
                    {`${
                      state.country.charAt(0).toUpperCase() +
                      state.country.slice(1)
                    }-${
                      state.port.charAt(0).toUpperCase() + state.port.slice(1)
                    }`}
                  </option>
                ))}
            </Form.Select>
            <Form.Text className={`text-error-2`}>
              {invalidScheduleProps.origin}
            </Form.Text>
          </Form.Group>
          <Form.Group
            className="flex-grow-1"
            style={{ width: "calc((100% - 10px) / 2)" }}
          >
            <Form.Label>Destination</Form.Label>
            <Form.Select
              className={invalidScheduleProps.origin && `border-error-2`}
              style={{ height: "44px" }}
              aria-label="Destination"
              value={schedule.destination}
              onChange={(e) =>
                onScheduleFormChange("destination", e.currentTarget.value)
              }
              disabled={tripProps.trip ? true : false || !!props.scheduleId}
            >
              <option hidden></option>
              {states
                .filter((state) => state.type == "destination")
                .map((state) => (
                  <option key={state._id} value={`${state._id}`}>
                    {`${
                      state.country.charAt(0).toUpperCase() +
                      state.country.slice(1)
                    }-${
                      state.port.charAt(0).toUpperCase() + state.port.slice(1)
                    }`}
                  </option>
                ))}
            </Form.Select>
            <Form.Text className={`text-error-2`}>
              {invalidScheduleProps.destination}
            </Form.Text>
          </Form.Group>
        </InputGroup>
        <InputGroup className="mb-3 flex flex-row">
          <Form.Group className="w-50 w-sm-100">
            <Form.Label>Departure</Form.Label>
            <React.Fragment>
              {props.scheduleId !== undefined ? (
                <ExtendedDatePicker
                  plugins={[<TimePicker position="bottom" hideSeconds />]}
                  format="DD/MM/YYYY HH:mm"
                  minDate={new Date()}
                  value={departures[0] && new Date(departures[0].departure)}
                  onChange={onDepartureDateChange}
                  inputClass={`${
                    invalidScheduleProps.departure && `border-error-2`
                  }`}
                  containerClassName="datetimepicker_wrapper"
                  calendarPosition="bottom-center"
                  fixMainPosition={true}
                  disabled={tripProps.trip ? true : false || !!props.scheduleId}
                />
              ) : (
                <ExtendedDatePicker
                  plugins={[
                    <TimePicker position="bottom" hideSeconds />,
                    <DatePanel markFocused position="right" />,
                  ]}
                  format="DD/MM/YYYY HH:mm"
                  multiple
                  value={departures.map((dt) => new Date(dt.departure))}
                  minDate={new Date()}
                  onChange={onDepartureDateChange}
                  inputClass={`datetimepicker ${
                    invalidScheduleProps.departure && `border-error-2`
                  }`}
                  containerClassName="datetimepicker_wrapper"
                  calendarPosition="bottom-center"
                  fixMainPosition={true}
                  disabled={tripProps.trip ? true : false}
                />
              )}
            </React.Fragment>
            <Form.Text className={`text-error-2`}>
              {invalidScheduleProps.departure}
            </Form.Text>
          </Form.Group>
        </InputGroup>
        <Form.Group style={{ marginBottom: "2.5rem" }}>
          <Form.Label>Cancellation Fee</Form.Label>
          <Form.Control
            type="text"
            style={{ width: "10rem" }}
            value={
              schedule.cancellationFee
                ? addSeperator(schedule.cancellationFee.toString())
                : 0
            }
            className={invalidScheduleProps.cancellationFee && `border-error-2`}
            onChange={(e) =>
              onScheduleFormChange(
                "cancellationFee",
                Number.isNaN(parseInt(removeNonNumeric(e.currentTarget.value)))
                  ? ""
                  : parseInt(removeNonNumeric(e.currentTarget.value))
              )
            }
          />
          <Form.Text className={`text-error-2`}>
            {invalidScheduleProps.cancellationFee}
          </Form.Text>
        </Form.Group>
        <div className="d-flex flex-row">
          <h3 className="text-decoration-underline mb-3">Rates</h3>
        </div>
        <Form.Check
          type="switch"
          label="Difer Foreigner"
          className="mb-3"
          checked={options.diferForeigner}
          onChange={(e) => OnSwitchChanged("difer-foreigner")}
        />
        <Form.Check
          type="switch"
          label="Has Two Way"
          className="mb-3"
          checked={options.hasTwoWay}
          onChange={(e) => OnSwitchChanged("has-two-way")}
        />
        <Form.Check
          type="switch"
          label="Has Agent Price"
          className="mb-3"
          checked={options.hasAgentPrice}
          onChange={(e) => OnSwitchChanged("has-agent-price")}
        />
        {ratesGrouping.map((dt) => {
          return (
            <div
              id={`rates-grouping-${dt.type}`}
              key={`rates-grouping-${dt.type}`}
            >
              {ratesComponent(dt.type, dt.rates)}
              <hr />
            </div>
          );
        })}
        <Button
          onClick={(e) => onScheduleSave()}
          variant="primary"
          className="w-140"
          type="submit"
        >
          Save Changes
        </Button>
      </Form>

      {/* Metro */}
      <Modal
        show={tripProps.show}
        onHide={() =>
          setTripProps((prev) => ({
            ...prev,
            show: false,
          }))
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <AsyncSelect
              isSearchable={false}
              isMulti={true}
              components={animatedComponents}
              menuPlacement="auto"
              placeholder="Select Schedule"
              getOptionLabel={(trip: Trip) => trip.date + " - " + trip.time}
              getOptionValue={(trip: Trip) => trip.tripId ?? ""}
              defaultInputValue={undefined}
              defaultValue={tripProps.trip}
              isClearable
              blurInputOnSelect
              noOptionsMessage={(e) => {
                return "No Schedule";
              }}
              isOptionSelected={(option, value) => {
                if (tripProps.tempTrip) {
                  return !!tripProps.tempTrip?.find(
                    (dt) => dt.tripId == option.tripId
                  );
                } else {
                  return !!tripProps.trip?.find(
                    (dt) => dt.tripId == option.tripId
                  );
                }
              }}
              onChange={(trips: MultiValue<Trip>, action: ActionMeta<Trip>) => {
                let selectedTrips = trips.map((dt) => dt);
                if (selectedTrips) {
                  setTripProps((prev) => ({
                    ...prev,
                    tempTrip: selectedTrips,
                  }));
                } else {
                  setTripProps((prev) => ({ ...prev, tempTrip: undefined }));
                }
              }}
              loadOptions={loadOptions}
              cacheOptions
              defaultOptions
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) =>
              setTripProps((prev) => ({
                ...prev,
                show: false,
                tempTrip: undefined,
              }))
            }
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onTripSelect}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScheduleForm;
