import { FC } from "react";
import { ReportsBookingsItem } from "../../../interfaces/reports.interface";
import { BookingStatus } from "../../../enums/booking.enum";
import { addSeperator } from "../../../utils/currencyValidator";
export interface DashboardCardProps {
  reportsBookings: ReportsBookingsItem[];
}

const data = (props: DashboardCardProps) => {
  const tempDashboardCardProps = {
    total: {
      count: 0,
      totalPrice: 0,
      totalFee: 0,
    },
    pending: {
      count: 0,
      totalPrice: 0,
      totalFee: 0,
    },
    cancel: {
      count: 0,
      totalPrice: 0,
      totalFee: 0,
    },
    completed: {
      count: 0,
      totalPrice: 0,
      totalFee: 0,
    },
  };
  props.reportsBookings.forEach((item) => {
    switch (item.status) {
      case BookingStatus.ISSUED:
        tempDashboardCardProps.completed.count += item.count;
        tempDashboardCardProps.completed.totalPrice += item.totalPrice;
        tempDashboardCardProps.completed.totalFee += item.totalFee;
        break;
      case BookingStatus.CANCELLED:
        tempDashboardCardProps.cancel.count += item.count;
        tempDashboardCardProps.cancel.totalPrice += item.totalPrice;
        tempDashboardCardProps.cancel.totalFee += item.totalFee;
        break;
      default:
        tempDashboardCardProps.pending.count += item.count;
        tempDashboardCardProps.pending.totalPrice += item.totalPrice;
        tempDashboardCardProps.pending.totalFee += item.totalFee;
        break;
    }

    tempDashboardCardProps.total.count += item.count;
    tempDashboardCardProps.total.totalPrice += item.totalPrice;
    tempDashboardCardProps.total.totalFee += item.totalFee;
  });
  return [
    {
      title: "Bookings",
      count: tempDashboardCardProps.total.count,
      totalPrice: tempDashboardCardProps.total.totalPrice,
      totalFee: tempDashboardCardProps.total.totalFee,
      description: `bookings`,
      icon: "/img/dashboard/icons/3.svg",
    },
    {
      title: "Pending",
      count: tempDashboardCardProps.pending.count,
      totalPrice: tempDashboardCardProps.pending.totalPrice,
      totalFee: tempDashboardCardProps.pending.totalFee,
      description: `pending bookings`,
      icon: "/img/dashboard/icons/1.svg",
    },
    {
      title: "Cancelled",
      count: tempDashboardCardProps.cancel.count,
      totalPrice: tempDashboardCardProps.cancel.totalPrice,
      totalFee: tempDashboardCardProps.cancel.totalFee,
      description: `cancelled bookings`,
      icon: "/img/dashboard/icons/4.svg",
    },
    {
      title: "Completed",
      count: tempDashboardCardProps.completed.count,
      totalPrice: tempDashboardCardProps.completed.totalPrice,
      totalFee: tempDashboardCardProps.completed.totalFee,
      description: `completed bookings`,
      icon: "/img/dashboard/icons/2.svg",
    },
  ];
};

const DashboardCard: FC<DashboardCardProps> = (props) => {
  return (
    <div className="row y-gap-30">
      {data(props).map((item, index) => (
        <div key={index} className="col-xl-3 col-md-6">
          <div className="py-30 px-30 rounded-4 bg-white shadow-3 flex-grow-1">
            <div className="row y-gap-20 justify-between items-center">
              <div className="col-auto col-md-8 col-lg-auto">
                <div className="fw-500 lh-14">{item.title}</div>
                {/* <div
                  className="text-26 lh-16 fw-600 mt-5"
                  style={{
                    maxHeight: "41.6px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.count}
                </div> */}
                <div className="text-26 lh-16 fw-600 mt-5 text-wrap text-break">
                  Rp
                  {addSeperator((item.totalPrice + item.totalFee).toString())}
                </div>
                <div className="text-15 lh-14 mt-5 text-wrap text-break">
                  <span className="fw-600">{item.count}</span>{" "}
                  <span className="text-light-1">{item.description}</span>
                </div>
              </div>
              <div className="col-12 col-md-auto col-xl-12 flex-shrink-0">
                <img src={item.icon} alt="icon" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCard;
