const parseObject = (key:string, value:any, out:any) => {
  const index = key.indexOf(".");
  const keysLeft = index >= 0 ? key.slice(index + 1) : "";
  key = key.slice(0, index >= 0 ? index : undefined);
  const keyNumber = parseInt(key);
  if (keysLeft) {
    if (isNaN(keyNumber)) {
      if (!out.hasOwnProperty(key)) {
        out[key] = {};
      }
      out[key] = parseObject(keysLeft, value, out[key]);
    } else {
      if (!Array.isArray(out)) {
        out = [];
      }
      for (let i = out.length; i <= keyNumber; i++) {
        out.push({});
      }
      out[keyNumber] = parseObject(keysLeft, value, out[key]);
    }
  } else {
    if (isNaN(keyNumber)) {
      out[key] = value;
    } else {
      out[keyNumber] = value;
    }
  }
  return out;
}

export const parseErrorToObject = (message:{[key:string]:string}) => {
  let data:any = {};
  const entries = Object.entries(message);
  for (let i = 0; i < entries.length; i++) {
    const key = entries[i][0];
    const value = entries[i][1];
    data = parseObject(key, value, data);
  }
  return data;
}