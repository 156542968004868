import { Provider } from "../../interfaces/provider.interface";
import http from "../axios";

export const getProviders = (
  q?: string,
  isActive?: boolean,
  page: number = 1,
  limit: number = 10,
  abortController?: AbortController
) => {
  const query = [`page=${page}`, `limit=${limit}`];
  if (q !== undefined) {
    query.push(`q=${q}`);
  }
  if (isActive !== undefined) {
    query.push(`isActive=${isActive ? "true" : "false"}`);
  }
  return http.get(`/admin/providers?${query.join("&")}`, {
    signal: abortController?.signal,
  });
};

export const getProvider = (id: string) => {
  return http.get(`/admin/providers/${id}`);
};

export const updateProvider = (id: string, provider: Provider) => {
  return http.put(`/admin/providers/${id}`, provider);
};

export const addProvider = (provider: Provider) => {
  return http.post("/admin/providers", provider);
};
