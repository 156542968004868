import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Provider } from "../../../interfaces/provider.interface";
import {
  addProvider,
  getProvider,
  updateProvider,
} from "../../../utils/rest-api/providers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../contexts/AppContext";
import AvatarUploader from "../../common/AvatarUploader";

export interface ScheduleFormProps extends PropsWithChildren {
  className?: string;
  providerId?: string;
}

const ProviderForm: FC<ScheduleFormProps> = (props) => {
  const [provider, setProvider] = useState<Provider>({
    _id: "",
    image: undefined,
    name: "",
    email: "",
    phone: "",
    isActive: false,
  });
  const [invalidProviderProps, setInvalidProviderProps] = useState({
    image: "",
    name: "",
    email: "",
    phone: "",
  });

  const navigate = useNavigate();
  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    if (props.providerId) {
      showLoader();
      getProvider(props.providerId)
        .then((res) => {
          const provider = res.data.data as Provider;
          if (provider.image) {
            provider.image = `${process.env.REACT_APP_API_URL}${provider.image}`;
          }
          setProvider({ ...provider });
        })
        .catch((e) => {
          toast.error(e.response.data.message ?? e.message);
        })
        .finally(() => hideLoader());
    }
  }, []);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProvider({ ...provider, [e.currentTarget.name]: e.currentTarget.value });
    setInvalidProviderProps({
      ...invalidProviderProps,
      [e.currentTarget.name]: "",
    });
  };

  const onActiveSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProvider({ ...provider, isActive: !provider.isActive });
  };

  const handleError = (err: any) => {
    if (err.response.data) {
      if (typeof err.response.data?.message == "object") {
        setInvalidProviderProps({
          ...invalidProviderProps,
          ...err.response.data.message,
        });
      } else {
        toast.error(err.response.data?.message ?? err.response.data.error);
      }
    } else {
      toast.error("Please check your network connection");
    }
  };

  const onSave = () => {
    const providerData = { ...provider };
    delete providerData._id;
    if (props.providerId) {
      showLoader();
      updateProvider(props.providerId, providerData)
        .then((res) => {
          toast.success("Provider successfully updated");
          navigate("/providers");
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => hideLoader());
    } else {
      showLoader();
      addProvider(providerData)
        .then((res) => {
          toast.success("Provider successfully added");
          navigate("/providers");
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => hideLoader());
    }
  };

  return (
    <div className={`ps-2 pe-2 pb-30 ${props.className}`}>
      <div className="mb-20">
        <span className="text-30 lh-14 fw-600">
          {props.providerId ? "Edit" : "Add"} Provider
        </span>
      </div>
      <Form className="w-form-provider" onSubmit={(e) => e.preventDefault()}>
        <Form.Group className="mb-2">
          <AvatarUploader
            image={provider.image}
            setImage={(image) => {
              setProvider({ ...provider, image: image });
              setInvalidProviderProps({
                ...invalidProviderProps,
                image: "",
              });
            }}
            error={invalidProviderProps.image}
            setError={(errorMessage) => {
              setInvalidProviderProps({
                ...invalidProviderProps,
                image: errorMessage,
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            className={invalidProviderProps.name && `border-error-2`}
            type="text"
            name="name"
            value={provider.name}
            onChange={onInputChange}
          />
          <Form.Text className={`text-error-2`}>
            {invalidProviderProps.name}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Email</Form.Label>
          <Form.Control
            className={invalidProviderProps.name && `border-error-2`}
            type="email"
            name="email"
            value={provider.email}
            onChange={onInputChange}
          />
          <Form.Text className={`text-error-2`}>
            {invalidProviderProps.email}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-20">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            className={invalidProviderProps.name && `border-error-2`}
            type="tel"
            name="phone"
            value={provider.phone}
            onChange={onInputChange}
          />
          <Form.Text className={`text-error-2`}>
            {invalidProviderProps.phone}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-40">
          <Form.Check
            type="switch"
            label="Active"
            name="isActive"
            checked={provider.isActive}
            onChange={onActiveSwitchChange}
          />
        </Form.Group>
        <Button onClick={onSave} variant="primary" className="w-100">
          Save
        </Button>
      </Form>
    </div>
  );
};

export default ProviderForm;
