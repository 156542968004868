import { FC, useState } from "react";
import { Form } from "react-bootstrap";
import {
  addSeperator,
  removeNonNumeric,
} from "../../../utils/currencyValidator";

export enum RateType {
  USER = "user",
  AGENT = "agent",
}

export enum RateCategory {
  INFANT = "infant",
  CHILD = "child",
  ADULT = "adult",
}

export type Rate = {
  category: RateCategory; // <2, <11, >11
  basePrice: number | string | undefined;
  margin: number | string | undefined;
  twoWay: boolean;
  isForeigner: boolean;
  type: RateType;
};

export interface RatesProps {
  sources: {
    rate: Rate;
    error: {
      basePrice: string;
      margin: string;
    };
  }[];
  onChange: (data: Rate, name: "basePrice" | "margin", value: string) => void;
}

const Rates: FC<RatesProps> = (props) => {
  const categories = ["Infant", "Child", "Adult"];
  const types = ["Local"];
  const diferForeigner = props.sources.find(
    (source) => source.rate.isForeigner === true
  );
  const hasTwoWay = props.sources.find((source) => source.rate.twoWay === true);
  if (diferForeigner) {
    types.push("Foreigner");
  }

  const getSources = (
    isForeinger: boolean,
    category: string,
    twoWay: boolean
  ) => {
    const index = props.sources.findIndex(
      (source) =>
        source.rate.isForeigner == isForeinger &&
        source.rate.category == category.toLowerCase() &&
        source.rate.twoWay == twoWay
    );
    return {
      rate: props.sources[index].rate,
      error: props.sources[index].error,
    };
  };

  const onInputChange = (
    rate: Rate,
    name: "basePrice" | "margin",
    value: string
  ) => {
    props.onChange(rate, name, removeNonNumeric(value));
  };

  return (
    <div className="d-flex flex-column">
      {types.map((type, i) => (
        <div key={`types-${i}`}>
          {diferForeigner && (
            <h6 className="h6 text-decoration-underline mr-4">{type}</h6>
          )}
          <div className="mb-3 rates-input-container" style={{ gap: "15px" }}>
            {categories.map((category, index) => {
              const isForeigner = type == "Foreigner";
              const { rate, error } = getSources(isForeigner, category, false);
              let basePrice = rate!.basePrice
                ? parseInt(rate!.basePrice.toString())
                : undefined;
              let margin = rate!.margin
                ? parseInt(rate!.margin.toString())
                : undefined;
              let basePriceNum = Number.isNaN(basePrice) ? 0 : basePrice;
              let marginNum = Number.isNaN(margin) ? 0 : margin;
              let finalPrice = undefined;
              if (!Number.isNaN(basePriceNum) && !Number.isNaN(marginNum)) {
                finalPrice = (basePriceNum ?? 0) + (marginNum ?? 0);
              }
              return (
                <Form.Group
                  key={`local-${index}`}
                  className="mb-3 flex-grow-1 rate-container"
                >
                  <div className="d-flex flex-row">
                    <Form.Label
                      style={{ fontSize: ".75rem" }}
                      className="mr-15"
                    >{`${category} ${
                      hasTwoWay ? "(One Way)" : ""
                    }`}</Form.Label>
                    <Form.Label style={{ fontSize: ".75rem" }}>
                      {finalPrice &&
                        `Rp ${addSeperator(finalPrice.toString())}`}
                    </Form.Label>
                  </div>
                  <div className="d-flex flex-row w-100 gap-2">
                    <div className="d-flex flex-column rate-input-container">
                      <Form.Label className={`rates-input-label`}>
                        Base price
                      </Form.Label>
                      <Form.Control
                        className={`${error?.basePrice && "border-error-2"}`}
                        name="basePrice"
                        type="text"
                        value={
                          basePrice ? addSeperator(basePrice.toString()) : 0
                        }
                        onChange={(e) =>
                          onInputChange(
                            rate!,
                            "basePrice",
                            e.currentTarget.value
                          )
                        }
                      />
                      <Form.Text className={`text-error-2`}>
                        {error?.basePrice}
                      </Form.Text>
                    </div>
                    <div className="d-flex flex-column rate-input-container">
                      <Form.Label className={`rates-input-label`}>
                        Margin
                      </Form.Label>
                      <Form.Control
                        className={`${error?.margin && "border-error-2"}`}
                        name="margin"
                        type="text"
                        value={margin ? addSeperator(margin.toString()) : 0}
                        onChange={(e) =>
                          onInputChange(rate!, "margin", e.currentTarget.value)
                        }
                      />
                      <Form.Text className={`text-error-2`}>
                        {error?.margin}
                      </Form.Text>
                    </div>
                  </div>
                </Form.Group>
              );
            })}
          </div>
          {hasTwoWay && (
            <div className="mb-3 rates-input-container" style={{ gap: "15px" }}>
              {categories.map((category, index) => {
                const isForeigner = type == "Foreigner";
                const { rate, error } = getSources(isForeigner, category, true);
                let basePrice = rate!.basePrice
                  ? parseInt(rate!.basePrice.toString())
                  : undefined;
                let margin = rate!.margin
                  ? parseInt(rate!.margin.toString())
                  : undefined;
                let basePriceNum = Number.isNaN(basePrice) ? 0 : basePrice;
                let marginNum = Number.isNaN(margin) ? 0 : margin;
                let finalPrice = undefined;
                if (!Number.isNaN(basePriceNum) && !Number.isNaN(marginNum)) {
                  finalPrice = (basePriceNum ?? 0) + (marginNum ?? 0);
                }
                return (
                  <Form.Group
                    key={`foreigner-${index}`}
                    className="mb-3 flex-grow-1 rate-container"
                  >
                    <div className="d-flex flex-row">
                      <Form.Label
                        style={{ fontSize: ".75rem" }}
                        className="mr-15"
                      >{`${category} ${
                        hasTwoWay ? "(Two Way)" : ""
                      }`}</Form.Label>
                      <Form.Label style={{ fontSize: ".75rem" }}>
                        {finalPrice &&
                          `Rp ${addSeperator(finalPrice.toString())}`}
                      </Form.Label>
                    </div>
                    <div className="d-flex flex-row w-100 gap-2">
                      <div className="d-flex flex-column rate-input-container">
                        <Form.Label className="rates-input-label">
                          Base price
                        </Form.Label>
                        <Form.Control
                          className={`${error?.basePrice && "border-error-2"}`}
                          name="basePrice"
                          type="text"
                          value={
                            basePrice ? addSeperator(basePrice.toString()) : 0
                          }
                          onChange={(e) =>
                            onInputChange(
                              rate!,
                              "basePrice",
                              e.currentTarget.value
                            )
                          }
                        />
                        <Form.Text className={`text-error-2`}>
                          {error?.basePrice}
                        </Form.Text>
                      </div>
                      <div className="d-flex flex-column rate-input-container">
                        <Form.Label className="rates-input-label">
                          Margin
                        </Form.Label>
                        <Form.Control
                          className={`${error?.margin && "border-error-2"}`}
                          name="margin"
                          type="text"
                          value={margin ? addSeperator(margin.toString()) : 0}
                          onChange={(e) =>
                            onInputChange(
                              rate!,
                              "margin",
                              e.currentTarget.value
                            )
                          }
                        />
                        <Form.Text className={`text-error-2`}>
                          {error?.margin}
                        </Form.Text>
                      </div>
                    </div>
                  </Form.Group>
                );
              })}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Rates;
