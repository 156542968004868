import React from "react";
import ReactLoading, { LoadingProps } from "react-loading";
import { useApp } from "../../contexts/AppContext";

interface LoaderProps extends LoadingProps {
  divheight?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { loaderVisibility } = useApp();
  return (
    loaderVisibility ?
      <div
        id="loader"
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          position: "fixed",
          zIndex: "9999",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          background: "#d3d3d399",
        }}>
        <ReactLoading {...props} />
      </div> : <React.Fragment />
  )
}

Loader.defaultProps = {
  divheight: "100%",
  type: "bubbles",
  color: "#3554d1",
};

export default Loader;
