import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { PageConfig } from "../../router";
import { Button, Form } from "react-bootstrap";
import ScheduleTable from "../../components/dashboard/schedule/ScheduleTable";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Schedule,
  ScheduleProvider,
} from "../../interfaces/schedule.interface";
import { useApp } from "../../contexts/AppContext";
import { getSchedules, updateSchedule } from "../../utils/rest-api/schedules";
import { toast } from "react-toastify";
import { ScheduleStatus } from "../../enums/schedule.enum";
import { ActionMeta, InputActionMeta, SingleValue } from "react-select";
import { getProviders } from "../../utils/rest-api/providers";
import { ExtendedSelect } from "../../components/common/ExtendedSelect";
import classNames from "classnames";
import { ExtendedDatePicker } from "../../components/common/ExtendedDatePicker";
import { DateObject } from "react-multi-date-picker";
import MetaComponent from "../../components/common/MetaComponent";
import dayjs from "dayjs";

const metadata = {
  title: "Luna - Schedule Page",
  description: "Luna - Schedule Page",
};

export default function SchedulePage() {
  const { state } = useLocation();
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const { showLoader, hideLoader } = useApp();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<{
    page: number;
    status: string;
    departures: Array<Date>;
    selectedProvider: ScheduleProvider;
  }>({
    page: 1,
    status: "all",
    departures: [],
    selectedProvider: {
      _id: undefined,
      name: "All",
    },
  });

  const onSaveStatusChange = (schedule: Schedule) => {
    showLoader();
    updateSchedule(schedule._id!, {
      status: schedule.status,
    })
      .then((res) => {
        toast.success("Schedule status successfully changed");
        const schedulesToChanged = [...schedules];
        schedulesToChanged.find((dt) => dt._id === schedule._id)!.status =
          schedule.status;
        setSchedules(schedulesToChanged);
      })
      .catch((err) => {})
      .finally(() => hideLoader());
  };

  const statuses = [
    "all",
    ScheduleStatus.PENDING,
    ScheduleStatus.OPEN,
    ScheduleStatus.CLOSED,
    ScheduleStatus.EXPIRED,
    ScheduleStatus.FULL,
  ];

  // const [status, setStatus] = useState("all");
  const onStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // setStatus(e.currentTarget.value);

    setFilter((prev) => ({ ...prev, page: 1, status: e.currentTarget.value }));
  };

  // const [selectedProvider, setSelectedProvider] = useState<ScheduleProvider>({
  //   _id: undefined,
  //   name: "All",
  // });

  const [providers, setProviders] = useState<ScheduleProvider[]>([]);
  let providerTimeoutId: MutableRefObject<NodeJS.Timeout | undefined> =
    useRef(undefined);
  const onProviderInputChange = (value: string, action: InputActionMeta) => {
    if (providerTimeoutId && providerTimeoutId.current) {
      clearTimeout(providerTimeoutId.current);
      providerTimeoutId.current = undefined;
    }
    if (action.action == "input-change") {
      providerTimeoutId.current = setTimeout(() => {
        getProviders(value, true, 1, 5)
          .then((res) => {
            setProviders(res.data.data);
          })
          .catch((e) => {});
        providerTimeoutId.current = undefined;
      }, 1000);
    }
  };

  const datepickerRef = useRef<any>(null);
  // const [departures, setDepartures] = useState<Array<Date>>([]);
  const onDepartureDateChange = (dates: DateObject[]) => {
    if (Array.isArray(dates) && dates.length == 2) {
      // setDepartures(dates.map((dt) => dt.toDate()));

      setFilter((prev) => ({
        ...prev,
        page: 1,
        departures: dates.map((dt) => dt.toDate()),
      }));
      datepickerRef.current?.closeCalendar();
    }
  };
  const onDepartureDateCleared = () => {
    // setDepartures([]);
    setFilter((prev) => ({ ...prev, page: 1, departures: [] }));
    datepickerRef.current?.closeCalendar();
  };

  useEffect(() => {
    showLoader();
    const promises = [];
    promises.push(
      getSchedules(
        filter.selectedProvider?._id,
        filter.departures.length > 0
          ? dayjs(filter.departures[0].setHours(0, 0, 0, 0)).format(
              "YYYY-MM-DDTHH:mm"
            )
          : undefined,
        filter.departures.length > 1
          ? dayjs(filter.departures[1].setHours(23, 59, 59, 59)).format(
              "YYYY-MM-DDTHH:mm"
            )
          : undefined,
        filter.status == "all" ? undefined : (filter.status as ScheduleStatus),
        filter.page
      )
        .then((res) => {
          const data = res.data.data;
          const total = res.data.totalPages;
          setTotalPages(total);
          setSchedules(data);
        })
        .catch((err) => {})
    );
    if (!providers || providers.length == 0) {
      promises.push(
        getProviders(undefined, true, 1, 5)
          .then((res) => {
            setProviders([{ _id: undefined, name: "All" }, ...res.data.data]);
            if (state?.provider) {
              const provider = res.data.data.find(
                (dt: any) => dt._id == state.provider
              );
              // setSelectedProvider(provider);

              setFilter((prev) => ({
                ...prev,
                page: 1,
                selectedProvider: provider,
              }));
            }
          })
          .catch((e) => {})
      );
    }
    Promise.all(promises).finally(() => hideLoader());
  }, [filter]);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 md-overflow-y-scroll">
            <div className="d-flex flex-row mb-20">
              <span className="text-30 lh-14 fw-600">Schedule</span>
            </div>
            <div
              className="d-flex md:flex-column flex-row mb-20"
              style={{ gap: "5px" }}
            >
              <ExtendedDatePicker
                format="DD/MM/YYYY"
                value={filter.departures}
                onChange={onDepartureDateChange}
                containerClassName="w-260 w-filter-control h-100"
                calendarPosition="bottom-center"
                fixMainPosition={true}
                range={true}
                ref={datepickerRef}
                highlightToday={false}
                placeholder="Departures"
              >
                <Button
                  className="mb-10"
                  onClick={(e) => onDepartureDateCleared()}
                >
                  Clear
                </Button>
              </ExtendedDatePicker>
              <ExtendedSelect
                classNames={{
                  container: () => "w-filter-control w-180 h-100",
                  control: ({ isFocused }) =>
                    classNames(isFocused && "react-select-focus", "h-100"),
                }}
                aria-label="Origin"
                getOptionLabel={(provider: ScheduleProvider) => provider.name}
                getOptionValue={(provider: ScheduleProvider) => provider._id!}
                options={providers}
                value={filter.selectedProvider as ScheduleProvider}
                onInputChange={onProviderInputChange}
                onChange={(
                  provider: SingleValue<ScheduleProvider>,
                  action: ActionMeta<ScheduleProvider>
                ) => {
                  // setSelectedProvider({
                  //   _id: provider?._id!,
                  //   name: provider?.name!,
                  // });

                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    selectedProvider: {
                      _id: provider?._id!,
                      name: provider?.name!,
                    },
                  }));
                }}
              />
              <Form.Select
                className="w-filter-control"
                style={{ height: "auto", width: "fit-content" }}
                value={filter.status}
                onChange={onStatusChange}
              >
                {statuses.map((status) => {
                  return (
                    <option key={`status-${status}`} value={status}>{`${status
                      .charAt(0)
                      .toUpperCase()}${status.slice(1)}`}</option>
                  );
                })}
              </Form.Select>
              <div className="flex-grow-1 md:d-none"></div>
              <Button
                variant="primary"
                onClick={(e) => navigate("/schedules/add")}
              >
                Add Schedule
              </Button>
            </div>
            <ScheduleTable
              className="h-100 md-overflow-y-visible overflow-y-scroll"
              data={schedules}
              total={totalPages}
              page={filter.page}
              onPageChange={(page) => {
                setFilter((prev) => ({ ...prev, page: page }));
              }}
              onEdit={(schedule) => navigate(`/schedules/${schedule._id}`)}
              onSaveStatusChange={(schedule) => onSaveStatusChange(schedule)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const SchedulePageConfig: PageConfig = {
  path: "/schedules",
  component: SchedulePage,
  roles: ["admin", "root"],
};
