import { FC, PropsWithChildren } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import { Location } from "../../../interfaces/location.interface";

export interface LocationTableProps extends PropsWithChildren {
  className?: string;
  page: number;
  total: number;
  data: Array<Location>;
  onEdit: (user: Location) => void;
  onPageChange: (page: number) => void;
}

const LocationTable: FC<LocationTableProps> = (props) => {
  return (
    <div className={props.className || ""}>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Country</th>
            <th>Port</th>
            <th>Type</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.data && props.data.length > 0 ? (
            props.data.map((dt, i) => {
              return (
                <tr key={`user-${i}`}>
                  <td>{dt.country}</td>
                  <td>{dt.port}</td>
                  <td>
                    <Badge bg="secondary">{dt.type}</Badge>
                  </td>
                  <td>
                    <Badge bg={dt.isActive ? `success` : "danger"}>
                      {dt.isActive ? "active" : "inactive"}
                    </Badge>
                  </td>
                  <td>
                    <Button variant="primary" onClick={(e) => props.onEdit(dt)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="text-center" colSpan={5}>
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination
        page={props.page}
        totalPage={props.total}
        onPageClick={(page) => props.onPageChange(page)}
      />
    </div>
  );
};

export default LocationTable;
