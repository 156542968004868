import { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import { PageConfig } from "../../router";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import ChartMain from "../../components/dashboard/dashboard/ChartMain";
import { useApp } from "../../contexts/AppContext";
import { getReportsBookings, getWeekly } from "../../utils/rest-api/reports";
import {
  ReportsBookingsItem,
  ReportWeekly,
} from "../../interfaces/reports.interface";
import MetaComponent from "../../components/common/MetaComponent";
import { DateObject } from "react-multi-date-picker";
import { getProviders } from "../../utils/rest-api/providers";
import {
  ProvidersResponse,
  ScheduleProvider,
} from "../../interfaces/schedule.interface";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { ActionMeta, SingleValue } from "react-select";
import { getBalances } from "../../utils/rest-api/balances";
import { convertToRupiah } from "../../utils/convertToRupiah";

const metadata = {
  title: "Luna - Admin Dashboard",
  description: "Luna - Admin Dashboard",
};

export default function DashboardPage() {
  const { showLoader, hideLoader } = useApp();

  const [selectedProvider, setSelectedProvider] = useState<ScheduleProvider>();

  const [weekly, setWeekly] = useState<Array<ReportWeekly>>([]);
  const [reportsBookings, setReportsBookings] =
    useState<Array<ReportsBookingsItem>>();
  const [monthlyReportsBookings, setMonthlyReportsBookings] =
    useState<Array<ReportsBookingsItem>>();
  const [agentTotalBalance, setAgentTotalBalance] = useState<number>();

  const animatedComponents = makeAnimated();
  let abortController: AbortController | undefined = undefined;
  let terminalTimeoutId: NodeJS.Timeout | undefined = undefined;

  const loadOptions = (
    inputValue: string,
    callback: (options: ScheduleProvider[]) => void
  ) => {
    if (terminalTimeoutId) {
      clearTimeout(terminalTimeoutId);
      terminalTimeoutId = undefined;
    }

    terminalTimeoutId = setTimeout(() => {
      abortController = new AbortController();
      getProviders(inputValue, true, 1, 10, abortController)
        .then((res: AxiosResponse<ProvidersResponse>) => {
          if (abortController) callback(res.data.data);
        })
        .catch((e) => {
          toast.error(
            e.response?.data?.message ??
              e.response?.data?.error ??
              e.message ??
              "Internal server error, please try again in a few minutes"
          );
          callback([]);
        })
        .finally(() => {
          abortController = undefined;
        });
      terminalTimeoutId = undefined;
    }, 1000);
  };

  const fetchData = () => {
    showLoader();

    let todayDate = new DateObject();

    axios
      .all([
        getWeekly({ provider: selectedProvider?._id }),
        getReportsBookings({
          startDate: todayDate.format("YYYY-MM-DD"),
          endDate: todayDate.format("YYYY-MM-DD"),
          provider: selectedProvider?._id,
        }),
        getReportsBookings({
          startDate: todayDate.toFirstOfMonth().format("YYYY-MM-DD"),
          endDate: todayDate.toLastOfMonth().format("YYYY-MM-DD"),
          provider: selectedProvider?._id,
        }),
        getBalances("agent"),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          // output of req.
          let reportWeekly = res1.data.data as Array<ReportWeekly>;
          setWeekly(reportWeekly);
          let reportsBookings = res2.data.data as Array<ReportsBookingsItem>;
          setReportsBookings(reportsBookings);
          let monthlyReportsBookings = res3.data
            .data as Array<ReportsBookingsItem>;
          setMonthlyReportsBookings(monthlyReportsBookings);
          let balances = res4.data.data as number;
          setAgentTotalBalance(balances);
        })
      )
      .finally(() => {
        hideLoader();
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedProvider]);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 mb-20 overflow-y-scroll">
            <div className="d-flex flex-row mb-20 gap-4">
              <span className="text-30 lh-14 fw-600">Dashboard</span>
              <AsyncSelect
                className="w-25"
                isSearchable={true}
                isMulti={false}
                components={animatedComponents}
                menuPlacement="auto"
                placeholder="All"
                getOptionLabel={(provider: ScheduleProvider) => provider.name}
                getOptionValue={(provider: ScheduleProvider) =>
                  provider._id ?? ""
                }
                defaultInputValue={undefined}
                defaultValue={undefined}
                isClearable
                blurInputOnSelect
                noOptionsMessage={(e) => {
                  return "No Provider";
                }}
                isOptionSelected={(option, value) => {
                  return selectedProvider?._id === option._id;
                }}
                onChange={(
                  provider: SingleValue<ScheduleProvider>,
                  action: ActionMeta<ScheduleProvider>
                ) => {
                  setSelectedProvider(provider ?? undefined);
                }}
                pageSize={1}
                loadOptions={loadOptions}
                cacheOptions
                defaultOptions
              />
            </div>
            <div className="h-100">
              <h2 className="text-18 lh-1 fw-500 pb-20">
                Today's booking Statistics
              </h2>
              {reportsBookings && (
                <DashboardCard reportsBookings={reportsBookings} />
              )}
              <h2 className="text-18 lh-1 fw-500 py-20">
                Monthly's booking Statistics
              </h2>
              {monthlyReportsBookings && (
                <DashboardCard reportsBookings={monthlyReportsBookings} />
              )}
              {agentTotalBalance && (
                <div className="pt-20">
                  <div className="py-30 px-30 rounded-4 bg-white shadow-3 text-26 lh-16 fw-600 text-wrap text-break">
                    <span className="text-18 lh-1 fw-500">
                      Agents' Remaining Balance:{" "}
                    </span>
                    {convertToRupiah(agentTotalBalance)}
                  </div>
                </div>
              )}
              <div className="row y-gap-30 pt-20 chart_responsive">
                <div className="col-xl-7 col-md-6">
                  <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="d-flex justify-between items-center">
                      <h2 className="text-18 lh-1 fw-500">
                        Weakly's booking Statistics
                      </h2>
                    </div>
                    <div className="pt-30">
                      <ChartMain
                        datasets={weekly.map((day) => {
                          return {
                            label: day.date,
                            amount: day.amount,
                            count: day.count,
                          };
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const DashboardPageConfig: PageConfig = {
  path: "/",
  component: DashboardPage,
  roles: ["admin", "root"],
};
