import { PageConfig } from "../../router";
import { useParams } from "react-router-dom";
import UserForm from "../../components/dashboard/user/UserForm";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - User Page",
  description: "Luna - User Page",
};

export default function EditUserPage() {
  const params = useParams();
  const userId = params.id;

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 mb-20 overflow-y-scroll">
            <UserForm userId={userId} />
          </div>
        </div>
      </div>
    </>
  );
}

export const EditUserPageConfig: PageConfig = {
  path: "/users/:id",
  component: EditUserPage,
  roles: ["admin", "root"],
};
