import { FC, PropsWithChildren, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./HeaderDashboard";
import { Outlet } from "react-router-dom";

const DashboardLayout: FC<PropsWithChildren> = (props) => {
  const [isSideBarOpen, setSideBarOpen] = useState(false);

  return (
    <>
      <div className="header-margin"></div>
      <Header isSideBarOpen={isSideBarOpen} setSideBarOpen={setSideBarOpen} />
      <div className="dashboard content">
        <div
          className={`position-fixed sidebar-background`}
          onClick={(e) => setSideBarOpen(false)}
        ></div>
        <div className="dashboard__sidebar bg-white scroll-bar-1">
          <Sidebar setSideBarOpen={setSideBarOpen} />
        </div>
        <div id="dashboard-content" className="w-100 h-100">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
