import React, { FC, useRef } from "react";

export interface AvatarUploaderProps {
  image?: string;
  setImage: (image?: string) => void;
  error: string;
  setError: (errorMessage: string) => void;
}

const AvatarUploader: FC<AvatarUploaderProps> = ({
  image,
  setImage,
  error,
  setError,
}) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files as FileList;
    const file = selectedFiles[0];
    const reader = new FileReader();

    if (!file) {
      console.log("file is null: ", image);
      if (!image || image.length <= 0) {
        setError("Please select an image.");
      }
      return;
    }

    if (!file.type.startsWith("image/")) {
      setError("Only image files are allowed.");
      return;
    }

    if (file.size > 800 * 1024) {
      setError("Image must be smaller than 800KB.");
      return;
    }

    if (!selectedFiles?.[0]) {
      setError("Fail");
      return;
    }

    reader.readAsDataURL(file);

    reader.onload = () => {
      setImage(`${reader.result}`);
      setError("");
    };
  };

  const handleReset = () => {
    setImage(undefined);
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  return (
    <div className="row y-gap-30 items-center">
      <div className="col-auto">
        {image ? (
          <div className="d-flex ratio ratio-1:1 w-200">
            <img
              src={`${image}`}
              alt="avatar"
              className="img-ratio rounded-4"
            />
            <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
              <div
                className="size-40 bg-white rounded-4 flex-center cursor-pointer"
                onClick={handleReset}
              >
                <i className="icon-trash text-16" />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex ratio ratio-1:1 w-200">
            <img
              src="/img/cruises/default-provider-icon.svg"
              alt="default-provider-icon.svg"
              className="img-ratio rounded-4 border-dark-1"
            />
            <div className="d-flex justify-end px-10 py-10 h-100 w-1/1 absolute">
              <div className="size-40 bg-white rounded-4 flex-center cursor-pointer">
                <i className="icon-trash text-16" />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="col-auto">
        <h4 className="text-16 fw-500">Your Logo</h4>
        <div className="text-14 mt-5">Image's size no bigger than 800KB.</div>
        <div className="d-inline-block mt-15">
          <label
            htmlFor="avatar-upload"
            role="button"
            className="button h-50 px-24 -dark-1 bg-blue-1 text-white"
          >
            <i className="icon-upload-file text-20 mr-10" />
            Browse
          </label>
          <input
            type="file"
            id="avatar-upload"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            ref={inputFile}
          />
        </div>
        {error.length > 0 && <div className="text-red-1 mt-1">{error}</div>}
      </div>
    </div>
  );
};

export default AvatarUploader;
