import { FC, useState } from "react";

export interface Pagination {
  totalPage: number,
  page: number,
  onPageClick: (page:number) => void
}

const Pagination:FC<Pagination> = (props) => {

  const onButtonClick = (selectedPage:number) => {
    if (selectedPage < 1 || selectedPage > props.totalPage) return;
    props.onPageClick(selectedPage);
  };

  const renderPage = (pageNumber:number|null, isActive = false, key:number) => {
    const className = `size-40 flex-center rounded-full cursor-pointer ${
      isActive ? "bg-dark-1 text-white" : ""
    }`;
    return (
      <div key={key} className="col-auto">
        {
          pageNumber == null ? 
            <div>...</div>
          : 
            <div className={className} onClick={() => props.onPageClick(pageNumber)}>
              {pageNumber}
            </div>
        }
      </div>
    );
  };

  const renderPages = (pageButtonCount:number = 7) => {
    const page = props.page;
    const totalPage = props.totalPage;
    const pageNumbers:Array<number|null> = [page];
    
    let nextPage = page + 1;
    let prevPage = page - 1;
    do {
      let hasPushed = false;
      if (prevPage >= 1) {
        pageNumbers.splice(0, 0, prevPage);
        hasPushed = true;
      }
      if (nextPage <= totalPage) {
        pageNumbers.push(nextPage)
        hasPushed = true;
      }
      if (!hasPushed) { // nothing to pushed left
        break;
      }
      nextPage++;
      prevPage--;
    } while (pageNumbers.length < pageButtonCount)

    if (totalPage > pageButtonCount) {
      if (Number(pageNumbers[0]) > 2) {
        pageNumbers[0] = 1;
        pageNumbers.splice(1, 0, null);
      }
      if (Number(pageNumbers[pageNumbers.length - 1]) < totalPage - 2) {
        pageNumbers.splice(pageNumbers.length - 1, 0, null);
        pageNumbers[pageNumbers.length - 1] = totalPage
      }
    }


    const pages = pageNumbers.map((pageNumber, index) =>
      renderPage(pageNumber, pageNumber === props.page, index),
    );
    return pages;
  };

  return (
    <div className="border-top-light mt-30 pt-30">
      <div className="d-flex flex-row x-gap-10 y-gap-20 justify-between md:justify-center">
        <div className="col-auto md:order-1">
          <button 
            className="button -blue-1 size-40 rounded-full border-light"
            onClick={() => onButtonClick(props.page - 1)}
          >
            <i className="icon-chevron-left text-12" />
          </button>
        </div>

        <div className="flex-grow-1 md:order-2">
          <div className="row x-gap-20 y-gap-20 justify-center items-center  md:d-none">
            {renderPages(7)}
          </div>

          <div className="x-gap-10 y-gap-20 justify-center items-center d-none md:d-flex">
            {renderPages(5)}
          </div>
        </div>

        <div className="md:order-3">
          <button 
            className="button -blue-1 size-40 rounded-full border-light"
            onClick={() => onButtonClick(props.page + 1)}>
            <i className="icon-chevron-right text-12" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
