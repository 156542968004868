import { login } from "../../utils/rest-api/auth";
import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from 'react-toastify';
import { useAuth } from "../../contexts/AuthContext";
import { Form } from "react-bootstrap";
import { useApp } from "../../contexts/AppContext";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const { setAuthenticate } = useAuth();

  const onInputChange = (e:ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.currentTarget.name]:e.currentTarget.value });
  }

  const { showLoader, hideLoader } = useApp();

  const onLoginFormSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    showLoader();
    login(formData.email, formData.password).then((res) => {
      const token = res.data.data.token;
      setAuthenticate(token);
    }).catch((e) => {
      toast.error(e.response.data.message ?? e.message);
    }).finally(() => hideLoader()); 
  }

  return (
    <Form className="row y-gap-20" onSubmit={onLoginFormSubmit}>
      <div className="col-12">
        <h1 className="text-22 fw-500">Welcome back</h1>
      </div>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control 
          className="form-control" 
          name="email" 
          type="email" 
          required 
          value={formData.email} 
          onChange={onInputChange}
          />
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control 
          className="form-control" 
          name="password" 
          type="password" 
          required 
          value={formData.password} 
          onChange={onInputChange}
          />
      </Form.Group>
      {/* <div className="col-12">
        <a href="#" className="text-14 fw-500 text-blue-1 underline">
          Forgot your password?
        </a>
      </div> */}
      <div className="col-12">
        <button
          type="submit"
          className="button py-20 -dark-1 bg-blue-1 text-white w-100"
        >
          Sign In <div className="icon-arrow-top-right ml-15" />
        </button>
      </div>
    </Form>
  )
}

export default LoginForm;
