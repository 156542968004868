import { PageConfig } from "../../router";
import LocationForm from "../../components/dashboard/location/LocationForm";
import MetaComponent from "../../components/common/MetaComponent";

const metadata = {
  title: "Luna - Location Page",
  description: "Luna - Location Page",
};

export default function AddLocationPage() {
  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 mb-20 overflow-y-scroll">
            <LocationForm />
          </div>
        </div>
      </div>
    </>
  );
}

export const AddLocationPageConfig: PageConfig = {
  path: "/locations/add",
  component: AddLocationPage,
  roles: ["admin", "root"],
};
