import { PageConfig } from "../../router";
import { useParams } from "react-router-dom";
import MetaComponent from "../../components/common/MetaComponent";
import ItinerayForm from "../../components/dashboard/booking/ItinerayForm";

const metadata = {
  title: "Luna - Itineray Page",
  description: "Luna - Itineray Page",
};

export default function ItinerayPage() {
  const params = useParams();
  const bookingId = params.id;
  return (
    <>
      <MetaComponent meta={metadata} />
      <ItinerayForm bookingId={bookingId} readonly />
    </>
  );
}

export const ItinerayPageConfig: PageConfig = {
  path: "/bookings/:id/itineray",
  component: ItinerayPage,
  roles: ["admin", "root"],
};
