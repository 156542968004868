import { forwardRef } from "react"
import DatePicker, { CalendarProps, DatePickerProps } from "react-multi-date-picker"

export const ExtendedDatePicker = forwardRef(function ExtendedDatePicker(props:Omit<CalendarProps, "onChange"> & DatePickerProps, ref:any) {
  return <DatePicker 
    {...props}
    inputClass={`datetimepicker ${props.inputClass} h-100`}
    containerClassName={props.containerClassName}
    className={props.className}
    ref={ref}
  >
    {props.children}
  </DatePicker>
});